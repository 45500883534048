import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Text extends Component {
  name() {
    return 'Circle'
  }

  labels() {
    return ['shapes']
  }

  icon() {
    return 'fa-regular fa-circle'
  }

  config() {
    return Layer.factory({
      layer_type: 'circle',
      width: 300,
      height: 300,
      background: 'red',
      config: {
        border_radius: '300',
        show_background: true,
      }
    })
  }
}
