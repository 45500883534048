import SalePrice from './pricing/sale_price'
import SalePercentage from './pricing/sale_percentage'
import SaleDifference from './pricing/sale_difference'

import ProductImage from './product/image'
import ProductTitle from './product/title'
import Price from './product/price'
import ProductScore from './product/review'
import AdditionalImages from './product/additional_images'
import ImageWithoutBackground from './product/image_without_background'

import GenericText from './generic/text'
// import GenericGroup from './generic/group'
import GenericTextArea from './generic/text_area'
import GenericImage from './generic/image'
import GenericTitle from './generic/title'
import GenericCTA from './generic/cta'
import GenericSubtitle from './generic/subtitle'
import GenericMedia from './generic/media'

import VideoSwiper from './video/swiper'
import VideoContentSlider from './video/contentslider'
// import VideoTexter from './video/texter'
import Background from './video/background'
import CubeSpinner from './video/cube_spinner'

import BrandkitReviewScore from './brandkit/review_score'
import BrandkitCustomerQuote from './brandkit/customer_quotes'
import BrandkitNumberOfReviews from './brandkit/number_of_reviews'
import BrandkitUSPS from './brandkit/usps'
import BrandkitStars from './brandkit/stars'
// import BrandkitLogo from './brandkit/logo'

import Rectangle from './shapes/rectangle'
import Circle from './shapes/circle'

const COMPONENTS = [GenericText, GenericImage, Rectangle, Circle, ProductImage,
  ImageWithoutBackground, AdditionalImages, GenericTextArea,
  ProductTitle, ProductScore, Price, SalePrice,
  SaleDifference, SalePercentage, BrandkitReviewScore, BrandkitStars,
  BrandkitNumberOfReviews, BrandkitCustomerQuote, BrandkitUSPS,
  Background, VideoSwiper, CubeSpinner]

export default {
  media_components(video, nightswatch = false) {
    const list = [
      new GenericMedia({ variable_tag: 'product', display_name: 'Media' }),

    ]
    if (video) {
      list.push(new Background({ variable_tag: 'lifestyle' }, {
        show_animations: true,
        animation: {
          delay_percentage: 0,
          duration_percentage: 100,
          in: null,
          while: null,
          out: null,
        },
        animations: [
          { type: 'inwhileout' }
        ]
      }))
      list.push(new VideoContentSlider({ variable_tag: 'Slider' }))
      if (nightswatch) {
        list.push(new CubeSpinner({ variable_tag: 'CubeSpinner' }))
      }
    } else {
      list.push(new Background({ variable_tag: 'lifestyle' }),)
    }
    console.log(list)
    return list
  },
  shape_components() {
    return [new Rectangle(), new Circle()]
  },
  text_components() {
    return [
      new GenericTitle({ variable_tag: 'title', display_name: 'Title', default_value: 'Title' }),
      new GenericSubtitle({ variable_tag: 'subtitle', display_name: 'Subtitle', default_value: 'Subtitle' }),
      new GenericCTA({ variable_tag: 'CTA', display_name: 'CTA', default_value: 'CTA' }),
      new GenericTextArea({ variable_tag: 'info', display_name: 'Info', default_value: 'info' }),
    ]
  },
  predefined_components() {
    return [
      new BrandkitStars({
        opts: {
          text: 9, fontSize: 50, width: 300, height: 100
        }
      }),
      // new BrandkitReviewScore(),
      // new BrandkitNumberOfReviews(),
      // new BrandkitCustomerQuote(),
      // new BrandkitUSPS(),
    ]
  },
  tree(kwargs) {
    const { addons } = kwargs
    const { video } = kwargs
    const tree = {}
    COMPONENTS.forEach((Component) => {
      const c = new Component()
      c.labels().forEach((label) => {
        if (!tree[label]) {
          tree[label] = []
        }
        if (c.required_addon() == null || addons.includes(c.required_addon())) {
          if (video && c.labels().includes('video')) {
            tree[label].push(c)
          } else if (!c.labels().includes('video')) {
            tree[label].push(c)
          }
        }
      })
    })
    Object.keys(tree).forEach((key) => {
      if (tree[key].length === 0) {
        delete tree[key]
      }
    })
    return tree
  }
}
