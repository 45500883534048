import Model from '../model'
import { mustache } from '../../../../lib/parsers'
import Animations from '../../../lib/animations'

class Layer extends Model {
  constructor({
    align, background, color, config, variable,
    fontSize, height, layer_type, matrix, id, translation, font, segments, dynamic_font_id,
    position, rotation, text, width, x, y, font_id, image_canvas_variant_id, dirty, deleted,
    locked_attributes, stencil_id, stencil, parent_id, variable_tag, unlinked_properties, visible
  }) {
    super()
    this.id = id || this.guid()
    this.persisted = !isNaN(this.id) // eslint-disable-line
    this.align = align
    this.unlinked_properties = unlinked_properties || []
    this.variable_tag = variable_tag
    this.background = background
    this.color = color
    this.variable = variable
    this.visible = visible === undefined || visible === null ? true : visible
    this.config = config
    this.fontSize = fontSize
    this.font = font
    this.stencil_id = stencil_id
    this.stencil = stencil
    this.height = height
    this.layer_type = layer_type
    this.matrix = matrix
    this.position = position
    this.rotation = rotation || 0
    this.text = text
    this.width = width
    this.parent_id = parent_id
    this.x = x
    this.y = y
    this.font_id = font_id
    this.image_canvas_variant_id = image_canvas_variant_id
    this.translation = translation || [0, 0]
    this.segments = segments || []
    this.dirty = dirty || false
    this.deleted = deleted || false
    this.dynamic_font_id = dynamic_font_id || null
    this.locked_attributes = locked_attributes || []
  }

  static get VISIBILITY_MODES() {
    return {
      ALL_VISIBLE: 'all_visible',
      ALL_HIDDEN: 'all_hidden',
      ONLY_THIS_VISIBLE: 'only_this',
      ONLY_THIS_HIDE: 'only_this_hide',
    }
  }

  static get synchoronized_properties() {
    return ['variable_tag', 'position', 'rotation',
      'align', 'background', 'color', 'text', 'variable', 'dynamic_font_id',
      'matrix', 'translation', 'font', 'fontSize', 'font_id'
    ]
  }

  static get LINK_BORDER() {
    return 'border'
  }

  static get LINK_VARIABLE() {
    return 'variable'
  }

  static get LINK_FONT_SIZE() {
    return 'fontSize'
  }

  static get LINK_ADJUSTMENTS() {
    return 'adjustments'
  }

  static get LINK_SHADOWS() {
    return 'dropshadows'
  }

  static get LINK_PADDING() {
    return 'padding'
  }

  static get LINK_ANIMATIONS() {
    return 'animations'
  }

  static get LINK_BACKGROUND() {
    return 'background'
  }

  static get LINK_HIGHLIGHTS() {
    return 'highlights'
  }

  static get LINK_TEXT_COLOR() {
    return 'text_color'
  }

  static get LINK_ALIGNMENT() {
    return 'alignment'
  }

  can_be_variable() {
    const exlcuded_types = ['group', 'rectangle', 'circle']
    return !exlcuded_types.includes(this.layer_type)
  }

  show_background() {
    return this.is_property_enabled('show_background')
  }

  show_animations() {
    return this.is_property_enabled('show_animations')
  }

  available_animations() {
    return Animations.available_animations_for(this)
  }

  animation_config() {
    if (this.config && this.config.animations && this.config.animations.length > 0) {
      return this.config.animations[0]
    }
    return {}
  }

  animation() {
    if (this.config && this.config.animations && this.config.animations.length > 0) {
      return Animations.by_type(this.config.animations[0].type)
    }
    return null
  }

  animations() {
    if (this.config && this.config.animations && this.config.animations.length > 0) {
      return this.config.animations.map((anim) => Animations.by_type(anim.type))
    }
    return []
  }

  is_property_enabled(property) {
    return !!this.config[property]
  }

  is_unlinked(property) {
    return new Set(this.unlinked_properties).has(property)
  }

  unlink(property) {
    if (this.is_unlinked(property)) return
    this.unlinked_properties = [...new Set([...this.unlinked_properties, property])]
  }

  link(property) {
    if (!this.is_unlinked(property)) return
    this.unlinked_properties = this.unlinked_properties.filter((p) => p !== property)
  }

  get_padding() {
    if (this.is_property_enabled('show_padding') === false) return {}
    const conf = this.config
    const paddings = ['top', 'right', 'bottom', 'left']
    const style = `${paddings.map((p) => conf[`padding_${p}`] || 0).join('px ')}px`
    return {
      padding: style
    }
  }

  resolve_static(brandkit) {
    // 'brandkit.logos'
    const STATIC_FIELDS = ['brandkit.primary_font_id', 'brandkit.secondary_font_id', 'brandkit.logos']

    STATIC_FIELDS.forEach((field) => {
      if (this.config && this.config.image_url
        && this.config.image_url.includes(field)) {
        this.config.image_url = mustache.fill_in(this.config.image_url, { brandkit })
      }
      if ((this.text || '').includes(field)) this.text = mustache.fill_in(this.text, { brandkit })

      if ((this.dynamic_font_id || '').includes(field)) {
        this.font_id = mustache.fill_in(this.dynamic_font_id, { brandkit })
        this.dynamic_font_id = null
      }
    })
    return this
  }

  px_to_perc(pos, px) {
    const rect = { width: this.width, height: this.height }
    return (100 / (rect[pos] / px)) || 0
  }

  h_align() {
    switch (this.align) {
      case 'left':
        return {
          'text-align': 'left',
          'justify-content': 'flex-start',
        }
      case 'center':
        return {
          'text-align': 'center',
          'justify-content': 'center',
        }
      case 'right':
        return {
          'text-align': 'right',
          'justify-content': 'flex-end',
        }
      default:
        return {}
    }
  }

  v_align() {
    switch (this.config.v_align) {
      case 'top':
        return {
          'align-items': 'flex-start',
        }
      case 'center':
        return {
          'align-items': 'center',
        }
      case 'bottom':
        return {
          'align-items': 'flex-end',
        }
      default:
        return {}
    }
  }

  abs_h_v_align() {
    let h = {}
    let v = {}
    switch (this.align) {
      case 'left':
        h = { left: `${0 + this.px_to_perc('width', this.config.padding_left)}%` }
        break
      case 'center':
        h = {
          left: `${44 + this.px_to_perc('width', this.config.padding_left)
            - this.px_to_perc('width', this.config.padding_right)}%`
        }
        break
      case 'right':
        h = { right: `${0 + this.px_to_perc('width', this.config.padding_right)}%` }
        break
      default:
        h = { left: 0 }
    }

    switch (this.config.v_align) {
      case 'top':
        v = { top: `${0 + this.px_to_perc('height', this.config.padding_top)}%` }
        break
      case 'center':
        v = {
          top: `${41 + this.px_to_perc('height', this.config.padding_top)
            - this.px_to_perc('height', this.config.padding_bottom)}%`
        }
        break
      case 'bottom':
        v = { bottom: `${0 + this.px_to_perc('height', this.config.padding_bottom)}%` }
        break
      default:
        v = { top: 0 }
    }
    return { ...h, ...v }
  }

  drop_shadow() {
    if (this.config.dropshadows && this.config.show_dropshadows) {
      const filters = this.config.dropshadows.map((shadow) => `drop-shadow(${shadow.dropshadow_h_distance}px ${shadow.dropshadow_v_distance}px ${shadow.dropshadow_blur}px ${shadow.dropshadow_color} )`).join(' ')
      return {
        '-webkit-filter': filters,
        filter: filters,
      }
    }
    return {}
  }

  strikethrough() {

  }

  styles() {
    return {
      ...this.get_padding(),
      ...this.h_align(),
      ...this.v_align(),
      'letter-spacing': `${this.config.letter_spacing || 0}px`,
      'word-spacing': `${this.config.word_spacing || 0}px`,
      'line-height': `${this.config.line_height || 1.2}`,
      ...this.drop_shadow(),
      ...this.strikethrough()
    }
  }

  static factory(opts = {}) {
    return new Layer({
      dynamic_font_id: null,
      persisted: false,
      dirty: false,
      deleted: false,
      fontSize: 32,
      font_id: null,
      color: 'black',
      background: 'transparent',
      align: 'left',
      text: '',
      width: 400,
      height: 50,
      y: 0,
      x: 0,
      position: 0,
      image_url: '',
      config: {},
      ...opts
    })
  }
}

export default Layer
