import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Media extends Component {
  constructor({ variable_tag, display_name, default_value } =
  { variable_tag: null, display_name: null, default_value: null }) {
    super({ variable_tag, display_name, default_value })
  }

  name() {
    return this.display_name || 'Title'
  }

  labels() {
    return ['layer']
  }

  icon() {
    return 'fa-regular fa-photo-film'
  }

  config(opts = {}) {
    return Layer.factory({
      layer_type: 'background',
      text: '',
      width: 500,
      height: 500,
      config: {
        object_fit: 'contain',
        media_url: '',
      },
      ...opts,
      variable: true,
      variable_tag: this.variable_tag,
    })
  }
}
