import anime from 'animejs/lib/anime'
import Scene from './scene'

export default class AnimationController {
  constructor({
    video_duration, scenes, uid, render_only, update_layer = () => { },
    complete = () => { }, begin = () => { },
    update = () => { }, callback = () => { }, loop = false
  }) {
    this.raw_scenes = scenes
    this.video_duration = video_duration
    this.update_layer = update_layer
    this.render_only = render_only
    this.paused = true
    this.virgin = true
    this.prepared = false
    this.progress = 0
    this.timeline_duration = 0
    this.uid = uid
    this.callback = callback
    this.eventTarget = new EventTarget()
    this.scenes = this.create_scenes(
      render_only,
      scenes,
      uid,
      video_duration,
      update_layer,
      callback
    )

    this.active_scene = this.scenes[0]
    this.loop = loop
    this.callbacks = {
      begin, update, complete
    }

    this.construct_timeline()
  }

  on(event, callback) {
    this.eventTarget.addEventListener(event, callback);
  }

  off(event, callback) {
    this.eventTarget.removeEventListener(event, callback);
  }

  emit(event, detail = {}) {
    this.eventTarget.dispatchEvent(new CustomEvent(event, { detail }));
  }

  reload(layers) {
    this.scenes.forEach((scene) => scene.reload(layers, this.timeline_duration))
    // this.construct_timeline()
  }

  create_scenes(render_only, scenes, uid, video_duration, update_layer, callback) {
    return scenes.map((scene) => new Scene({
      controller: this,
      render_only,
      scene,
      uid,
      video_duration,
      update_layer,
      callback: ({ scene, index, sample }) => {
        // this.active_scene = scene
        callback({ scene, index, sample })
      }
    }))
  }

  get_duration() {
    return this.scenes.map((s) => s.get_duration())
      .reduce((a, b) => a + b)
  }

  construct_timeline() {
    this.timeline = anime.timeline({
      loop: this.loop,
      autoplay: false,
      duration: this.get_duration(),
      update: (anim) => {
        this.progress = anim.progress
        this.cur_time = anim.currentTime
        if (this.callbacks.update) this.callbacks.update(anim)
        this.emit('update', anim);
      },
      begin: (anim) => {
        this.timeline_duration = anim.duration
        if (this.callbacks.begin) this.callbacks.begin(anim)
        this.emit('begin', anim);
      },
      complete: (anim) => {
        if (this.callbacks.complete) {
          if (this.render_only === false) {
            // this.cleanup()
          }
          this.callbacks.complete(anim)
          this.emit('complete', anim);
        }
        if (!this.render_only) {
          this.virgin = true
          this.paused = true
          // this.construct_timeline()
        }
        // if (this.render_only) document.title = 'video_finished'
      }
    })

    this.timeline.add({
      targets: '.pixel',
      opacity: [0, 1],
      duration: this.get_duration(),
      loop: true,
      easing: 'linear',
      direction: 'alternate'
    }, 0)

    this.scenes.forEach((scene) => {
      scene.timeline(this.loop)
    })
  }

  animations() {
    if (this.active_scene) {
      return [...this.active_scene.animations].sort((a, b) => a.delay - b.delay)
    }
    return []
  }

  grouped_animations() {
    const groupedAnims = this.animations().reduce((acc, anim) => {
      if (acc[anim.layer.id]) {
        acc[anim.layer.id].push(anim)
      } else {
        acc[anim.layer.id] = [anim]
      }
      return acc
    }, {})
    return groupedAnims
  }

  seek(time) {
    this.timeline.seek(time)
    this.scenes.forEach((scene) => scene.seek(time))
  }

  reset(duration = this.get_duration()) {
    this.pause()
    this.virgin = true
    this.paused = true
    this.progress = 0
    this.cur_time = 0
    this.video_duration = duration
    this.timeline_duration = duration
    this.emit('update', {
      progress: 0,
      cur_time: 0
    })
    this.construct_timeline()
    this.reload()
  }

  cleanup() {
    this.prepared = false
    this.scenes.forEach((scene) => scene.cleanup())
  }

  duration() {
    return this.timeline.duration
  }

  prepare() {
    this.scenes.forEach((scene) => scene.prepare())
    this.prepared = true
  }

  playPause() {
    if (this.paused) this.play()
    else this.pause()
  }

  pause() {
    this.paused = true
    this.virgin = false
    if (this.timeline) this.timeline.pause()
    this.scenes.forEach((scene) => scene.pause())
  }

  get playing() {
    return !this.paused
  }

  play() {
    if (this.paused === false) return
    this.paused = false
    if (this.virgin) this.prepare()
    this.timeline.play()
    this.scenes.forEach((scene) => scene.play())
  }
}
