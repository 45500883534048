var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TextConfig',{attrs:{"layer":_vm.layer,"fonts":_vm.fonts,"mediaFolder":_vm.mediaFolder,"onUpdate":_vm.onUpdate,"palette":_vm.palette,"nightswatch":_vm.nightswatch,"inputName":'Price'}}),_vm._v(" "),_c('Collapseable',{attrs:{"label":"Strikethrough"}},[_c('div',{staticClass:"form-group",staticStyle:{"font-size":"14px"}},[_c('div',{staticClass:"button-group--select"},[_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            strikethrough_angle: 0,
            strikethrough: false
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.strikethrough),expression:"strikethrough"}],attrs:{"type":"radio","name":"strikethrough_angle"},domProps:{"value":false,"checked":_vm._q(_vm.strikethrough,false)},on:{"change":function($event){_vm.strikethrough=false}}}),_vm._v(" "),_c('label',[_c('span',[_vm._v("\n            €10"+_vm._s(_vm.layer.config.decimal)+"00\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            strikethrough_angle: 10,
            strikethrough: true
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.strikethrough_angle),expression:"strikethrough_angle"}],attrs:{"type":"radio","value":"10","name":"strikethrough_angle"},domProps:{"checked":_vm._q(_vm.strikethrough_angle,"10")},on:{"change":function($event){_vm.strikethrough_angle="10"}}}),_vm._v(" "),_c('label',[_c('span',{staticClass:"editor__strikethrough-diagonal",staticStyle:{"--price-strikethrough-deg":"10deg","--price-strikethrough-width":"1px","--price-strikethrough-color":"black"}},[_vm._v("\n            €10"+_vm._s(_vm.layer.config.decimal)+"00\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            strikethrough_angle: 1,
            strikethrough: true
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.strikethrough_angle),expression:"strikethrough_angle"}],attrs:{"type":"radio","value":"1","name":"strikethrough_angle"},domProps:{"checked":_vm._q(_vm.strikethrough_angle,"1")},on:{"change":function($event){_vm.strikethrough_angle="1"}}}),_vm._v(" "),_c('label',[_c('span',{staticClass:"editor__strikethrough-diagonal",staticStyle:{"--price-strikethrough-deg":"1deg","--price-strikethrough-width":"1px","--price-strikethrough-color":"black"}},[_vm._v("\n          €10"+_vm._s(_vm.layer.config.decimal)+"00\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            strikethrough_angle: 170,
            strikethrough: true
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.strikethrough_angle),expression:"strikethrough_angle"}],attrs:{"type":"radio","value":"170","name":"strikethrough_angle"},domProps:{"checked":_vm._q(_vm.strikethrough_angle,"170")},on:{"change":function($event){_vm.strikethrough_angle="170"}}}),_vm._v(" "),_c('label',[_c('span',{staticClass:"editor__strikethrough-diagonal",staticStyle:{"--price-strikethrough-deg":"170deg","--price-strikethrough-width":"1px","--price-strikethrough-color":"black"}},[_vm._v("\n          €10"+_vm._s(_vm.layer.config.decimal)+"00\n        ")])])])])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Color")]),_vm._v(" "),_c('ColorField',{attrs:{"show_dynamic":true,"element":_vm.element,"element_key":'color',"palette":_vm.palette}})],1)]),_vm._v(" "),_c('Collapseable',{attrs:{"label":"Price formatting"}},[_c('div',{staticClass:"form-group",staticStyle:{"font-size":"14px"}},[_c('div',[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.hide_separator},on:{"change":function (e) {
          _vm.updateLayerConfig({separator: e.target.checked ? '' : _vm.layer.config.separator || '.'})
        }}}),_vm._v("\n        Hide separator?\n      ")]),_vm._v(" "),_c('div',{staticClass:"button-group--select"},[_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            separator: _vm.hide_separator ? '' : '.',
            decimal: ','
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sep_dec),expression:"sep_dec"}],attrs:{"type":"radio","value":"EU","name":"sep_dec"},domProps:{"checked":_vm._q(_vm.sep_dec,"EU")},on:{"change":function($event){_vm.sep_dec="EU"}}}),_vm._v(" "),_c('label',[_c('span',[_vm._v("€1"+_vm._s(_vm.hide_separator ? '' : '.')+"000,00")])])]),_vm._v(" "),_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            separator: _vm.hide_separator ? '' : ',',
            decimal: '.'
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sep_dec),expression:"sep_dec"}],attrs:{"type":"radio","value":"US","name":"sep_dec"},domProps:{"checked":_vm._q(_vm.sep_dec,"US")},on:{"change":function($event){_vm.sep_dec="US"}}}),_vm._v(" "),_c('label',[_c('span',[_vm._v("€1"+_vm._s(_vm.hide_separator ? '' : ',')+"000.00")])])])])]),_vm._v(" "),_c('div',{staticClass:"form-group",staticStyle:{"font-size":"14px"}},[_c('label',[_vm._v("Show symbol?")]),_vm._v(" "),_c('div',{staticClass:"button-group--select"},[_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            show_symbol: true,
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.show_symbol),expression:"show_symbol"}],attrs:{"type":"radio","value":"true","name":"show_symbol"},domProps:{"checked":_vm._q(_vm.show_symbol,"true")},on:{"change":function($event){_vm.show_symbol="true"}}}),_vm._v(" "),_c('label',[_c('span',[_vm._v("€10"+_vm._s(_vm.layer.config.decimal)+"00")])])]),_vm._v(" "),_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            show_symbol: false,
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.show_symbol),expression:"show_symbol"}],attrs:{"type":"radio","value":"false","name":"show_symbol"},domProps:{"checked":_vm._q(_vm.show_symbol,"false")},on:{"change":function($event){_vm.show_symbol="false"}}}),_vm._v(" "),_c('label',[_c('span',[_vm._v("10"+_vm._s(_vm.layer.config.decimal)+"00")])])])])]),_vm._v(" "),_c('div',{staticClass:"form-group",staticStyle:{"font-size":"14px"}},[_c('label',[_vm._v("Truncate zeros?")]),_vm._v(" "),_c('div',{staticClass:"button-group--select"},[_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            truncate_trailing_zeros: false,
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.truncate_trailing_zeros),expression:"truncate_trailing_zeros"}],attrs:{"type":"radio","value":"false","name":"truncate_zeros"},domProps:{"checked":_vm._q(_vm.truncate_trailing_zeros,"false")},on:{"change":function($event){_vm.truncate_trailing_zeros="false"}}}),_vm._v(" "),_c('label',[_c('span',[_vm._v("€10"+_vm._s(_vm.layer.config.decimal)+"00")])])]),_vm._v(" "),_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            truncate_trailing_zeros: true,
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.truncate_trailing_zeros),expression:"truncate_trailing_zeros"}],attrs:{"type":"radio","value":"true","name":"truncate_zeros"},domProps:{"checked":_vm._q(_vm.truncate_trailing_zeros,"true")},on:{"change":function($event){_vm.truncate_trailing_zeros="true"}}}),_vm._v(" "),_c('label',[_c('span',[_vm._v("€10"+_vm._s(_vm.layer.config.decimal)+"-")])])]),_vm._v(" "),_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            truncate_trailing_zeros: 'hide_if_zero',
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.truncate_trailing_zeros),expression:"truncate_trailing_zeros"}],attrs:{"type":"radio","value":"hide_if_zero","name":"truncate_zeros"},domProps:{"checked":_vm._q(_vm.truncate_trailing_zeros,"hide_if_zero")},on:{"change":function($event){_vm.truncate_trailing_zeros="hide_if_zero"}}}),_vm._v(" "),_c('label',[_c('span',[_vm._v("€10")])])])])]),_vm._v(" "),_c('div',{staticClass:"form-group",staticStyle:{"font-size":"14px"}},[_c('label',[_vm._v("Superscript?")]),_vm._v(" "),_c('div',{staticClass:"button-group--select"},[_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            superscript: false,
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.superscript),expression:"superscript"}],attrs:{"type":"radio","value":"false","name":"superscript"},domProps:{"checked":_vm._q(_vm.superscript,"false")},on:{"change":function($event){_vm.superscript="false"}}}),_vm._v(" "),_c('label',[_c('span',[_vm._v("€10"+_vm._s(_vm.layer.config.decimal)+"45")])])]),_vm._v(" "),_c('div',{staticClass:"button--select button--select-half-width",on:{"click":function (e){
          _vm.updateLayerConfig({
            superscript: true,
          })
        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.superscript),expression:"superscript"}],attrs:{"type":"radio","value":"true","name":"superscript"},domProps:{"checked":_vm._q(_vm.superscript,"true")},on:{"change":function($event){_vm.superscript="true"}}}),_vm._v(" "),_c('label',[_c('span',[_vm._v("€10"+_vm._s(_vm.layer.config.decimal)),_c('sup',[_vm._v("45")])])])])])])])],1)}
var staticRenderFns = []
export { render, staticRenderFns }