export default class Component {
  constructor({ variable_tag, display_name, default_value } =
  { variable_tag: null, display_name: null, default_value: null }) {
    this.variable_tag = variable_tag
    this.display_name = display_name
    this.default_value = default_value
  }

  icon() {
    return 'fa-regular fa-font'
  }

  required_addon() {
    return null
  }
}
