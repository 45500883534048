export const icon = (layer) => {
  switch (layer.layer_type) {
    case 'group':
      return 'fa fa-object-group'
    case 'swiper':
    case 'slider':
      return 'fa fa-arrows-h'
    case 'background':
      return 'fa-regular fa-photo-film'
    case 'image':
    case 'logo':
      return 'fa fa-picture-o'
    case 'text':
      return 'fa fa-font'
    case 'price':
      return 'fa fa-eur'
    case 'review':
      return 'fa fa-star'
    case 'rectangle':
      return 'fa-regular fa-rectangle'
    case 'circle':
      return 'fa-regular fa-circle'
    case 'additional_images':
      return 'fa fa-th-large'
    case 'text_area':
      return 'fa-regular fa-align-left'
    default:
      return 'fa fa-font'
  }
}

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const fixSpecialChars = (string) => string.replaceAll('&SINGLE_QUOTE&', "'")

export const allResourcesLoaded = function () {
  return new Promise((resolve) => {
    let totalResources = 0;
    let loadedResources = 0;

    // Scan for IMG tags and background images in CSS
    const images = Array.from(document.getElementsByTagName('img'));
    const elemsWithBg = Array.from(document.querySelectorAll('*')).filter((el) => {
      const style = window.getComputedStyle(el);
      return style.backgroundImage !== 'none' && style.backgroundImage !== '';
    });

    // Scan for VIDEO tags
    const videos = Array.from(document.getElementsByTagName('video'));

    totalResources += images.length + elemsWithBg.length + videos.length;

    if (totalResources === 0) {
      // No resources to load
      resolve();
      return;
    }

    const handleLoadedResource = () => {
      loadedResources += 1;
      if (loadedResources === totalResources) {
        resolve();
      }
    };

    const handleBrokenResource = () => {
      totalResources -= 1;
      if (loadedResources === totalResources) {
        resolve();
      }
    };

    // Handle IMG tags
    for (const img of images) { // eslint-disable-line no-restricted-syntax
      img.addEventListener('load', handleLoadedResource);
      img.addEventListener('error', handleBrokenResource);
    }

    // Handle background images
    for (const el of elemsWithBg) { // eslint-disable-line no-restricted-syntax
      const img = new Image();
      img.addEventListener('load', handleLoadedResource);
      img.addEventListener('error', handleBrokenResource);
      img.src = el.style.backgroundImage.slice(4, -1).replace(/"/g, ''); // Remove url() and quotes
    }

    // Handle VIDEO tags
    for (const video of videos) { // eslint-disable-line no-restricted-syntax
      video.addEventListener('loadeddata', handleLoadedResource);
      video.addEventListener('error', handleBrokenResource);
    }
  });
}
