import _ from 'lodash'
import Layer from './model'

export const getLinkedLayers = (layers, layer) => {
  const linked_layers = layers.filter((l) => {
    const is_sibling = l.parent_id && layer.parent_id && l.parent_id === layer.parent_id
    const incoming_is_parent = l.parent_id && l.parent_id === layer.id
    const incoming_is_child = layer.parent_id && l.id === layer.parent_id
    return is_sibling || incoming_is_parent || incoming_is_child
  })

  // Add the layer itself if it is a parent
  if (!layer.parent_id) {
    linked_layers.push(layer)
  }

  return linked_layers
}

export const updateLinkedLayers = (layers, layer) => {
  layers = layers.map((l) => new Layer({ ...l }))
  layer = new Layer({ ...layer })

  const linked_layers = getLinkedLayers(layers, layer)

  const updated_linked_layers = linked_layers.map((l) => {
    const propertyLinkMap = {
      color: Layer.LINK_TEXT_COLOR,
      background: Layer.LINK_BACKGROUND,
      align: Layer.LINK_ALIGNMENT,
      fontSize: Layer.LINK_FONT_SIZE
    };

    Layer.synchoronized_properties.forEach((prop) => {
      if (propertyLinkMap[prop]
        && (l.is_unlinked(propertyLinkMap[prop])
          || layer.is_unlinked(propertyLinkMap[prop]))) {
        // Add some code here if needed
      } else {
        l[prop] = layer[prop];
      }
    })
    const conf = _.cloneDeep(layer.config)

    // TODO
    // transform is not a property that should be copied
    // if we do want to copy it we should copy it to scale of its corresponding variant size
    delete conf.transform

    if (l.is_unlinked(Layer.LINK_ADJUSTMENTS) || layer.is_unlinked(Layer.LINK_ADJUSTMENTS)) {
      delete conf.opacity
      delete conf.border_radius
    }

    if (l.is_unlinked(Layer.LINK_ALIGNMENT) || layer.is_unlinked(Layer.LINK_ALIGNMENT)) {
      delete conf.v_align
    }

    if (l.is_unlinked(Layer.LINK_SHADOWS) || layer.is_unlinked(Layer.LINK_SHADOWS)) {
      delete conf.dropshadows
    }

    if (l.is_unlinked(Layer.LINK_PADDING) || layer.is_unlinked(Layer.LINK_PADDING)) {
      delete conf.padding_top
      delete conf.padding_bottom
      delete conf.padding_left
      delete conf.padding_right
    }

    if (l.is_unlinked(Layer.LINK_HIGHLIGHTS) || layer.is_unlinked(Layer.LINK_HIGHLIGHTS)) {
      delete conf.highlights
    }

    if (l.is_unlinked(Layer.LINK_ANIMATIONS) || layer.is_unlinked(Layer.LINK_ANIMATIONS)) {
      delete conf.animations
      delete conf.animation
    }

    l.config = { ...l.config, ...conf }
    return new Layer({ ...l })
  })
  return { linked_layers, updated_linked_layers }
}
