<template>
  <div class="layer-config__essentials">
    <div class="form-group">
      <textarea ref="layerText" rows="3" type="text" :value="layer.text"
        @input="(e) => updateLayer('text', e.target.value)">
        </textarea>
    </div>
    <TextConf :layer="layer" :is_studio="is_studio" :version="version" :fonts="fonts" :onUpdate="onUpdate"
      :inputName="inputName" :show_wrap="false" :palette="palette" :nightswatch="nightswatch" :show_input="false" />
  </div>
</template>

<script>
import ColorField from '../../feeds/fields/color.vue'
import Select from '../../components/select.vue'
import { mustache } from '../../lib/parsers'
import Lock from './lock.vue'
import TextConf from './text.vue'

export default {
  props: ['layer', 'fonts', 'mediaFolder', 'onUpdate', 'inputName', 'palette', 'nightswatch', 'is_studio', 'version'],
  components: { ColorField, Select, Lock, TextConf },
  data() {
    return {
      element: {
        color: { hex: this.layer.color },
      }
    }
  },
  computed: {
    selected_font_id() {
      if (this.layer.dynamic_font_id) {
        return mustache.fill_in(this.layer.dynamic_font_id, { brandkit: this.$root.$store.state.brandkit })
      }
      return this.layer.font_id
    },
    font() {
      return this.fonts.find(f => f.id == this.selected_font_id) || { id: null, family: null, name: null, format: null }
    },
    brandkit_font_families() {
      let brandkit = this.$root.$store.state.brandkit

      if (brandkit) {
        const brandkit_fonts = []

        if (brandkit.primary_font_id) {
          brandkit_fonts.push({
            matcher: '{{brandkit.primary_font_id}}',
            prefix: '(Primary)',
            font: null
          })
        }

        if (brandkit.secondary_font_id) {
          brandkit_fonts.push({
            matcher: '{{brandkit.secondary_font_id}}',
            prefix: '(Secondary)',
            font: null
          })
        }

        return brandkit_fonts.map(bf => {
          bf.font = this.fonts.find(f => f.id == mustache.fill_in(bf.matcher, { brandkit }))
          return bf
        }).filter(bf => !!bf.font)

        // let brandkit_font_ids = ['{{brandkit.primary_font_id}}', '{{brandkit.secondary_font_id}}']
        // let selected = [this.fonts.find(f => f.id == this.layer.font_id && brandkit_font_ids.includes(this.layer.font_id))]
        // return this.fonts.reduce((memo, font) =>{
        //   if(!memo.find(f => (f && font) ? (f.family == font.family) : false)){
        //     if(brandkit_font_ids.includes(font.id)){
        //       memo.push(font)
        //     }
        //   }
        //   return memo
        // }, selected.filter(Boolean)).sort((a, b)=> a.family.localeCompare(b.family))
      } else {
        return null
      }
    },
    organization_font_families() {
      let selected = [this.fonts.find(f => f.id == this.layer.font_id && !!f.organization_id)]
      return this.fonts.reduce((memo, font) => {
        if (!memo.find(f => (f && font) ? (f.family == font.family) : false)) {
          if (!!font.organization_id === true) {
            memo.push(font)
          }
        }
        return memo
      }, selected.filter(Boolean)).sort((a, b) => a.family.localeCompare(b.family))
    },
    font_families() {
      let selected = [this.fonts.find(f => f.id == this.layer.font_id)]
      return this.fonts.reduce((memo, font) => {
        if (!memo.find(f => (f && font) ? (f.family == font.family) : false)) {
          if (!font.organization_id)
            memo.push(font)
        }
        return memo
      }, selected.filter(Boolean)).sort((a, b) => a.family.localeCompare(b.family))
    },
  },
  watch: {
    element: {
      deep: true,
      handler(newVal) {
        this.updateLayer('color', newVal.color.hex8)
      }
    }
  },
  methods: {
    font_formats(font) {
      return this.fonts.filter(f => f.family.includes(font.family))
        .sort((a, b) => a.format.localeCompare(b.format))
    },
    updateLayerConfig(conf) {
      this.updateLayer('config', { ...this.layer.config, ...conf })
    },
    updateLayer(key, value) {
      const layer = { ...this.layer, [key]: value }
      this.$props.onUpdate(layer)
    },
    setFont(fontId) {
      const font = this.fonts.find(f => f.id === parseInt(fontId))
      this.$props.onUpdate({
        ...this.layer,
        font: font ? font.filename : '',
        dynamic_font_id: null,
        font_id: parseInt(fontId)
      })
      this.$forceUpdate()
    },
    setDynamicFont(brandkit_font) {
      const font = brandkit_font.font
      this.$props.onUpdate({
        ...this.layer,
        font: font ? font.filename : '',
        font_id: brandkit_font.font.id,
        dynamic_font_id: brandkit_font.matcher
      })
      this.$forceUpdate()
    },
    align(align) {
      this.$props.onUpdate({ ...this.layer, align })
    },
  }
}
</script>
