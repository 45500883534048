<template>
	<div>
		
	</div>
</template>
  
<script> 
  export default  {
    props: ['layer', 'samples', 'disabled', 'brandkit', 'animation_uid'],
    data(){
      return {
       
      }
    },
    watch: {
    
    },
    computed: {

    },
    methods: {
     
    }
  }
  </script>
  