<template>

  <div v-if="editor_canvas && !headless" style="display: flex; flex-direction: column; flex: 1">
    <Medialib :show="false" :feed_id="feed ? feed.complex_identifier : null">
      <template v-slot:custom_tab_selector>
        <i class="fa-regular fa-image"></i> Brandkit
      </template>
      <template v-slot:custom_tab_content="slot_props">
        <div class="briefing_wizard__output_body">
          <div>
            My Logo's
            <i>
              <p><a :href="`/brands/${brandkit.brand_id}/edit`">Manage brandkit</a></p>
            </i>
            <hr>
            <div style="display: flex; overflow-x: scroll; padding-bottom: 8px">
              <div :key="key" style="cursor: pointer;"
                v-on:click="e => slot_props.pick_asset({ image_link: asset, asset_url: asset })"
                v-for="(asset, key) in brandkit.logos">
                <Thumb class="thumb" style="margin-right: 8px;" :url="asset" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Medialib>

    <div class="editor__main-panel-navs">
      <div class="editor__main-panel-navs__variants">
        <Dropdown :active="show_dropdown" :root="true" :on_toggle="state => show_dropdown = state">
          <template slot="btn" slot-scope="{is_active}">
            <div class="button button__editor-nav" :class="{ 'button--active': is_active }">
              <i class="fa-solid fa-plus"></i>
            </div>
          </template>
          <div :key="key" v-for="key in Object.keys(layer_components)">
            <div class="v-dropdown__header">{{ cap(key) }}</div>
            <div :key="component.name()" class="v-dropdown__option"
              v-on:click="() => addLayer(component.config({ canvas }))" v-for="component in layer_components[key]">
              <i :class="component.icon()" class="lighten"></i>{{ cap(component.name()) }}
            </div>
          </div>
        </Dropdown>

        <div v-if="show_import_export" style="display: flex;">
          <button data-modal-id="editor-import" class="modal__open button button__editor-nav">
            <i class="fa-regular fa-file-import" aria-hidden="true"></i>
          </button>
          <button v-on:click="export_template" class="button button__editor-nav">
            <i class="fa-regular fa-file-export" aria-hidden="true"></i>
          </button>
          <button class="button button__editor-nav" :class="{ 'nightswatch--alert': s_canvas.template }"
            v-on:click="update_editor_canvas({ ...s_canvas, template: !s_canvas.template })">
            <i class="fa-solid fa-head-side-goggles"></i>
          </button>
        </div>

        <Dropdown :active="show_filters" v-if="!is_studio" :root="true" :on_toggle="state => show_filters = state">

          <template slot="btn" slot-scope="{is_active}">
            <div class="editor__main-panel-filter" :class="{
    'editor__main-panel-filter--hover': is_active,
    'editor__main-panel-filter--active': _self['top_segment'] && !!_self['top_segment'].name
  }">

              <i class="fa-regular fal fa-filter" aria-hidden="true"></i>
              <i v-if="_self['top_segment'] && _self['top_segment'].name">
                {{ cap(_self['top_segment'].name) }}
              </i>
              <span v-else></span>
            </div>
          </template>

          <div>
            <div class="v-dropdown__option" v-on:click="() => set_top_segment({ name: '' })">
              No filter
            </div>
            <div v-if="_self['feeds/segments'] && _self['feeds/segments'].length > 0" class="v-dropdown__header">Feed
            </div>
            <div class="v-dropdown__option" v-on:click="() => set_top_segment(segment)" :key="segment['id']"
              v-for="segment in _self['feeds/segments']">
              {{ cap(segment.name) }}
            </div>
            <div class="v-dropdown__header">Generic</div>
            <div class="v-dropdown__option" v-on:click="() => set_top_segment(segment)" :key="segment['id']"
              v-for="segment in segments.filter(s => s.id)">
              {{ cap(segment.name) }}
            </div>
          </div>
        </Dropdown>
        <span v-if="!is_studio"><i> &nbsp; / &nbsp; {{ cap(_self['variants/active'].name) }}</i></span>
      </div>


      <div class="editor__main-panel-navs__variants" v-if="is_studio">
        <div @click="setActiveVariant(variant)" class="editor__size-variant"
          :class="{ 'editor__size-variant--active': variant.id == _self['variants/active'].id }" :key="variant.id"
          v-for="variant in _self['variants/list']">
          <div class="icon-ar" :class="`icon-ar--${size_for(variant).aspect_icon}`" aria-hidden="true"></div>
          <div class="editor__size-variant--tag">
            <span>{{ size_for(variant).w }}x{{ size_for(variant).h }}</span>
            <i v-on:click.stop="edit_variant(variant)" class="fa-solid fa-file-pen"></i>
            <i v-if="!variant.default" v-on:click.stop="() => delete_variant(variant.id)"
              class="fa-regular fa-trash-can">
            </i>
          </div>
        </div>
      </div>
      <div class="editor__main-panel-navs__variants" v-else>
        <div class="editor__size-variant">
          <i :class="size_for(_self['variants/active']).icon" class="fab"></i>
          <div class="editor__size-variant--tag">
            <span style="font-size:12px;">{{ size_for(_self['variants/active']).w }}x{{
    size_for(_self['variants/active']).h
  }}</span>
            <i v-on:click.stop="edit_variant(_self['variants/active'])" class="fa-solid fa-file-pen"></i>
          </div>
        </div>
      </div>

      <div class="editor__main-panel-navs__feed-nav">

        <div v-if="_self['feeds/samples'].data.length == 0 && !sampling">
          <div class="notify notify__warning">
            <i class="fa-solid fa-diamond-exclamation canvas__warning"></i>
            <p>No products found</p>
          </div>
        </div>
        <div v-if="sampling && feed" class="loader"></div>
        <p><span class="strong">{{ _self['feeds/samples'].indice + 1 }}</span> of {{ _self['feeds/samples'].data.length
          }}
        </p>
        <button class="button button__editor-nav" v-on:click="previous_sample">
          <i class="fa-solid fa-backward"></i>
        </button>
        <button class="button button__editor-nav" v-on:click="next_sample">
          <i class="fa-solid fa-forward"></i>
        </button>
        <button class="button__editor-nav" :disabled="!history_store.can_undo()" v-on:click="undo"
          style="margin-right: 1px;">
          <i class="fa-solid fa-rotate-left"></i>
        </button>
        <button class="button__editor-nav" :disabled="!history_store.can_redo()" style="margin-left: 1px;"
          v-on:click="redo">
          <i class="fa-solid fa-rotate-right"></i>
        </button>
        <button class="button button__editor-nav" :class="{ 'button__toggle--active': show_settings }"
          v-on:click="toggle_settings()">
          <i class="fa-regular fa-cog" aria-hidden="true"></i>
        </button>
        <button id="editor-save" class="button button__editor-nav">
          <i class="fa-solid fa-save"></i>
        </button>
      </div>
    </div>

    <div class="editor__holder">
      <div class="studio__sidebar">
        <div class="studio__sidebar__navs">
          <div class="editor__sidebar__nav" v-if="show_data && is_studio" v-on:click="tab = 'data'"
            :class="{ 'editor__sidebar__nav--active': this.tab == 'data' }">
            <span v-if="wide_submenu">
              <i class="fa-regular fa-chevron-left" aria-hidden="true"></i>
              <p>Back</p>
            </span>
            <span v-else>
              <i class="fa-regular fa-film" aria-hidden="true"></i>
              <p>Data</p>
            </span>
          </div>
          <div class="editor__sidebar__nav" v-if="wide_submenu === false" v-on:click="tab = 'layers'"
            :class="{ 'editor__sidebar__nav--active': this.tab == 'layers' }">
            <i class="fa-regular fa-layer-group" aria-hidden="true"></i>
            <p>Layers</p>
          </div>
          <div class="editor__sidebar__nav" v-on:click="tab = 'variables'" v-if="wide_submenu === false"
            :class="{ 'editor__sidebar__nav--active': this.tab == 'variables' }">
            <i class="fa-solid fa-brackets-curly" aria-hidden="true"></i>
            <p>Variables</p>
          </div>
          <div class="editor__sidebar__nav" v-if="show_templates && wide_submenu === false && templates.length > 0"
            v-on:click="tab = 'templates'" :class="{ 'editor__sidebar__nav--active': this.tab == 'templates' }">
            <i class="fa-regular fa-paint-brush" aria-hidden="true"></i>
            <p>Templates</p>
          </div>
          <div class="editor__sidebar__nav" v-on:click="tab = 'swatches'" v-if="wide_submenu === false"
            :class="{ 'editor__sidebar__nav--active': this.tab == 'swatches' }">
            <i class="fa-regular fa-palette" aria-hidden="true"></i>
            <p>Brandkit</p>
          </div>
        </div>

        <div class="studio__sidebar__body">
          <div v-if="s_canvas.template" style="width: 100%; padding: 10px; text-align: center;"
            class="nightswatch--alert">
            Templating mode
          </div>

          <div v-show="this.tab == 'data'" class="studio__sidebar__body__small-holder"
            :class="{ 'studio__sidebar__body__wide-holder': wide_submenu }">
            <LayerData :onUpdate="updateLayer" :layers="_self['layers/all_variable']" v-if="canvas_version > 1" />
            <FeedEditor v-else :feed_id="s_canvas.feed_id || null" :feed="s_canvas.feed || { data: [] }"
              :show_spreadsheet="wide_submenu" :on_override="e => wide_submenu = e" :schema="s_canvas.feed_schema"
              :slurp="s_canvas.slurp" :brandkit="brandkit" :hidden_fields="show_import_export ? [] : ['brandkit']"
              :on_row_update="(feed, row) => update_canvas_feed(feed, row)" />
          </div>

          <div class="studio__sidebar__body__small-holder" v-if="this.tab == 'templates'">
            <div :key="template.name" v-for="template in templates">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div class="design__holder--large" v-on:click="import_template(template.template, false)">
                  <Canvas class="design__scaler--large" :disabled="true" :is_animated="video.enabled"
                    :size="{ w: template.template.width, h: template.template.height }"
                    :layers="template.template.layers" :background_color="template.template.background_color"
                    :background="template.template.background_image" :samples="_self['feeds/samples']" />
                </div>
                <label style="margin-top:8px;">{{ template.name }}</label>
              </div>
              <br />
            </div>

          </div>

          <div class="editor__sidebar-holder" v-if="this.tab == 'stencils'">
            <label>Stencils</label>
            <hr>
            <div :key="`stencil_${stencil.id}`" v-for="stencil in stencils">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div class="design__holder--large" v-on:click="addLayer(LayerModel.factory({
    width: canvas.width,
    height: canvas.height,
    stencil_id: stencil.id,
    layer_type: 'stencil'
  }))">
                  <Canvas class="design__scaler--large" :disabled="true" :is_animated="video.enabled"
                    :size="{ w: stencil.width, h: stencil.height }" :layers="stencil.layers"
                    :background_color="stencil.background_color" :background="stencil.background_image"
                    :samples="_self['feeds/samples']" />
                </div>
                <label style="margin-top:8px;">{{ stencil.name }}</label>
              </div>
              <br />
            </div>
          </div>

          <div v-if="this.tab == 'layers'" class="studio__sidebar__body__small-holder">
            <LayerList v-bind:layers="_self['layers/list']" v-bind:activeLayer="_self['layers/active']"
              v-bind:onSetActive="setActiveLayer" v-bind:onAddLayer="addLayer" v-bind:on_layer_update="updateLayer"
              v-bind:onOrderChange="(layers) => updateLayerOrder(layers)"
              :on_layer_visibility_change="on_layer_visibility_change" />
          </div>

          <div v-if="this.tab == 'variables'" class="studio__sidebar__body__small-holder">
            <div v-if="_self['feeds/samples'].data[0]">
              <div class="editor__variable-item">
                <div class="editor__variable-item__name">
                  [[sale_pct]]
                </div>
                <div class="editor__variable-item__icon">
                </div>
              </div>
              <div class="editor__variable-item">
                <div class="editor__variable-item__name">
                  [[sale_diff]]
                </div>
                <div class="editor__variable-item__icon">
                </div>
              </div>

              <div class="editor__variable-item" v-for="key in _self['feeds/keys']" :key=key>
                <div class="editor__variable-item__name">
                  {{ build_key(key) }}
                </div>
                <div class="editor__variable-item__icon">
                </div>
              </div>
            </div>
          </div>

          <div class="studio__sidebar__body__small-holder" v-if="this.tab == 'swatches'">
            <PaletteSelector :on_select="(palette) => set_palette(palette)" :brandkit="brandkit" :add_layer="addLayer"
              :selected="s_canvas.palette" :project_id="project_id" style="margin-right: 25px;" />
          </div>
        </div>
      </div>

      <div class="editor__main-panel" id="teleportTo">

        <div class="editor__main-panel-body" id="editor__main-panel" @click.self="unsetActiveLayer">
          <div class="editor__main-panel-zoom">
            <div class="editor__main-panel-zoom__slider">
              <input type="range" min="10" step="5" :value="slider_zoom * 100" max="300" @input="set_zoom">
            </div>
            <div style="display: inline-block;">
              {{ parseInt(slider_zoom * 100) }}%
            </div>
          </div>

          <div class="editor__main-panel-overlay" v-if="ui_state !== 'STATE_DEFAULT'"
            v-on-clickaway="() => set_ui_state('STATE_DEFAULT')">
            <SizeSelector v-if="ui_state === 'STATE_PICK_VARIANT_SIZE'" :canvas="s_canvas"
              :excluded="_self['variants/list'].map(v => typeof v.size === 'string' ? v.size : v.size.name)"
              :on_change="is_studio ? updateVariant : update_editor_canvas" :disabled="s_canvas.used_by_catalog"
              :filter="is_studio ? ['pinterest_square', 'pinterest_standard'] : []" />

            <SizeSelector v-if="ui_state === 'STATE_NEW_VARIANT'" :canvas="s_canvas"
              :excluded="_self['variants/list'].map(v => typeof v.size === 'string' ? v.size : v.size.name)"
              :on_change="s => add_variant({ size: s.size })" :disabled="s_canvas.used_by_catalog"
              :filter="is_studio ? ['pinterest_square', 'pinterest_standard'] : []" />

            <button class="button button--white" v-on:click="e => set_ui_state('STATE_DEFAULT')">Close</button>
          </div>

          <InfiniteViewer :objectDimensions="{ width: comp_size.w, height: comp_size.h }" ref="viewer" :zoom="zoom"
            :onPinch="e => pinch(e)">

            <div>
              <Canvas v-bind:layers="_self['layers/list']" v-bind:background="editor_canvas.background_image"
                v-bind:is_animated="video.enabled" v-bind:id="editor_canvas.id" :version="canvas_version"
                v-bind:background_color="editor_canvas.background_color" v-bind:size="comp_size"
                v-bind:activeVariant="_self['variants/active']" v-bind:activeLayer="_self['layers/active']"
                v-bind:scaleHeight="scaleHeight" v-bind:onLayerSelect="setActiveLayer"
                v-bind:onLayerDelete="deleteLayer" v-bind:onLayerUpdate="updateLayer" :force_default_brandkit="true"
                v-bind:samples="_self['feeds/samples']" v-bind:segments="_self['feeds/segments']"
                v-bind:animation_uid="`variant_${_self['variants/active'].id}`" v-bind:show_settings="show_settings"
                v-bind:zoom="parseFloat(zoom)" :editor_uid="editor_uid" ref="canvas" />

              <Moveable v-if="_self['layers/active']" :layer="_self['layers/active']" :on_update="update_moveable"
                :zoom="1" :active="true" :disabled="false" :editor_uid="editor_uid" />
            </div>
          </InfiniteViewer>
        </div>
      </div>

      <div v-if="show_variants === false">
        <div @click="show_variants = !show_variants" class="editor__variant-toggle editor__variant-toggle--in"
          v-if="!is_studio || video.enabled">
          <i class="fas fa-caret-up"></i>
        </div>
      </div>
      <div v-else>
        <div @click="show_variants = !show_variants" class="editor__variant-toggle" v-if="!is_studio || video.enabled">
          <i class="fas fa-caret-down"></i>
        </div>
      </div>

      <div class="editor__property-panel">
        <Settings :showSettings="show_settings" :version="canvas_version" :show_feed_selector="show_feed_selector"
          :show_sizes="show_sizes" :onFeedSelect="sample_feed" :onCanvasUpdate="update_editor_canvas" :feeds="feeds"
          :canvas="s_canvas" :is_studio="is_studio" />

        <LayerConfig v-bind:activeLayer="_self['layers/active']" v-bind:segments="_self['feeds/segments']"
          v-bind:fonts="fonts" v-bind:palette="brandkit.palette" v-bind:onUpdate="updateLayer" :version="canvas_version"
          v-bind:onClose="unsetActiveLayer" v-bind:onDelete="deleteLayer" v-bind:isVideo="video.enabled"
          :brandkit="brandkit" v-bind:samples="_self['feeds/samples']" v-bind:nightswatch="show_import_export"
          v-bind:show_segments="show_segments" :is_studio="is_studio" />
      </div>

      <Sizebar :canvas="s_canvas" :sizes="_self['variants/list']" :layers="_self['layers/all']"
        :on_size_select="variant => setActiveVariant(variant)" v-if="is_studio && canvas_version > 1" />

      <div id="editor-export" class="modal modal--small">
        <div class="modal__header">
          <h3> Export design </h3>
          <div class="modal__close"><i class="fa-regular fa-times" aria-hidden="true"></i></div>
        </div>
        <div class="modal__body">
          <textarea disabled v-model="exp" rows="20"></textarea>
        </div>
      </div>

      <div id="editor-import" class="modal modal--small">
        <div class="modal__header">
          <h3> Import design </h3>
          <div class="modal__close"><i class="fa-regular fa-times" aria-hidden="true"></i></div>
        </div>
        <div class="modal__body">
          <textarea v-model="imp" rows="20"></textarea>
        </div>
        <div class="modal__footer">
          <button v-on:click="() => import_template(imp)">Import</button>
        </div>
      </div>

      <div id="editor-status" ref="editor_status" class="modal modal--small">
        <div class="modal__flex">
          <div class="modal__header">
            <h3> </h3>
            <div class="modal__close"><i class="fa-regular fa-times" aria-hidden="true"></i></div>
          </div>
          <div class="modal__flex_body">
            <div class="loader loader--big"></div>
            <br />
            <p class="center">{{ status }}</p>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div class="editor__variant-bar" v-if="show_variants && !is_studio" ref="variant-bar">
      <label style="color: #CCC">Variants</label>
      <div style="margin-top: 7px; display: flex">
        <div @click="setActiveVariant(variant)" class="editor__variant-bar-variant-holder"
          :class="{ 'editor__variant-bar-variant-holder--active': variant.id == _self['variants/active'].id }"
          :key="variant.id" v-for="variant in _self['variants/list']">
          <div class="editor__variant-bar-variant">
            <div class="catalog__canvas--preview-small">
              <Canvas v-bind:layers="fix_layer_ordering(variant.layers)"
                v-bind:background="editor_canvas.background_image"
                v-bind:background_color="editor_canvas.background_color"
                v-bind:size="{ w: s_canvas.width, h: s_canvas.height }" v-bind:activeVariant="variant"
                v-bind:is_animated="video.enabled" v-bind:force_default_brandkit="true"
                v-bind:samples="_self['feeds/samples']" v-bind:segments="_self['feeds/segments']"
                v-bind:disabled="true" />
            </div>
            <div v-if="!variant.default" class="editor__variant-delete">
              <i v-on:click.stop="() => delete_variant(variant.id)" class="fa-regular fa-trash-can"
                style="cursor: pointer;">
              </i>
            </div>
          </div>
          <div class="editor__variant-name">
            {{ variant.name }}
          </div>
        </div>
        <Dropdown :active="show_conditional_creation" v-if="!is_studio" :root="true" :pos="'top_right'" :y_offset="-250"
          :x_offset="48" :on_toggle="state => show_conditional_creation = state">
          <template slot="btn" slot-scope="{is_active}">
            <div class="editor__variant-new">
              <i class="fas fa-plus"></i>
            </div>
          </template>
          <div>
            <div v-if="_self['feeds/segments'] && _self['feeds/segments'].length > 0" class="v-dropdown__header">If...
            </div>

            <div class="v-dropdown__option" v-on:click="() => add_variant({ segment })" :key="segment['id']"
              v-for="segment in segments.filter(s => !_self['variants/list'].map(v => v.segment_id).includes(s.id))">
              {{ cap(segment.name) }}
            </div>

            <div class="v-dropdown__option" v-on:click="() => add_variant({ segment })" :key="segment['id']"
              v-for="segment in _self['feeds/segments']">
              {{ cap(segment.name) }}
            </div>
          </div>
        </Dropdown>
      </div>
    </div>

    <!-- video part -->
    <div v-if="show_variants === false">
      <div @click="show_variants = !show_variants" class="editor__variant-toggle editor__variant-toggle--in"
        v-if="!is_studio || video.enabled">
        <i class="fas fa-caret-up"></i>
      </div>
    </div>
    <div v-else>
      <div @click="show_variants = !show_variants" class="editor__variant-toggle" v-if="!is_studio || video.enabled">
        <i class="fas fa-caret-down"></i>
      </div>
    </div>

    <div class="video__timeline-toolbar" v-if="video.controller && video.enabled">
      <button class="video__timeline-play" v-on:click="() => video.controller.paused ? play() : pause()">
        <i v-if="video.controller.paused" class="fa-solid fa-play"></i>
        <i v-else class="fa-solid fa-pause"></i>
      </button>
      <div class="video__timeline-time">
        {{ parseFloat(video.time / 1000).toFixed(2) }}s / {{ (video.controller.get_duration() / 1000).toFixed(2) }}s
      </div>
    </div>

    <AnimationTimeline :layers="_self['layers/list']" :show_variants="show_variants" :video="video"
      :on_animation_select="(animation) => setActiveLayer(animation.layer)" />

  </div>
  <div v-else ref="viewercontainer" @click="focus" style="width: 100%; height: 100%">
    <HeadlessLayerConf v-bind:activeLayer="_self['layers/active']" v-bind:updateLayer="updateLayer" :canvas="s_canvas"
      :sizes="_self['variants/list']" :layers="_self['layers/all']" :active_size="_self['variants/active'].size"
      :brandkit="brandkit" v-bind:fonts="fonts" v-bind:palette="brandkit.palette" :version="canvas_version"
      :deleteLayer="deleteLayer" :on_variant_change="variant_changed" :min_scene_duration="min_scene_duration" />

    <InfiniteViewer :objectDimensions="{ width: comp_size.w, height: comp_size.h }" ref="viewer" :zoom="zoom"
      :onPinch="e => pinch(e)">
      <div>
        <Canvas :version="canvas_version" v-bind:layers="_self['layers/list']" v-bind:id="editor_canvas.id"
          v-bind:background="editor_canvas.background_image" v-bind:background_color="editor_canvas.background_color"
          v-bind:is_animated="video.enabled" v-bind:size="comp_size" v-bind:activeVariant="_self['variants/active']"
          v-bind:activeLayer="_self['layers/active']" v-bind:scaleHeight="scaleHeight"
          v-bind:force_default_brandkit="true" v-bind:onLayerSelect="setActiveLayer" v-bind:onLayerDelete="deleteLayer"
          v-bind:onLayerUpdate="updateLayer" v-bind:samples="feed ? _self['feeds/samples'] : samples"
          v-bind:segments="_self['feeds/segments']" v-bind:show_settings="show_settings" v-bind:zoom="parseFloat(zoom)"
          :disbled="disabled" :animation_uid="animation_uid" :animation_progress="animation_progress"
          :animation_complete="animation_complete" :min_scene_duration="min_scene_duration"
          :on_animation_controller_init="emit_anim_controller_init" :editor_uid="editor_uid" ref="canvas" />

        <Moveable v-if="_self['layers/active']" :layer="_self['layers/active']" :zoom="zoom"
          :on_update="update_moveable" :active="true" :disabled="false" :editor_uid="editor_uid" />
      </div>
    </InfiniteViewer>
  </div>
</template>

<script>
import InfiniteViewer from './infinite_scroll.vue'
import Settings from './settings.vue'
import Canvas from './canvas.vue'
import LayerList from './layer_list.vue'
import LayerConfig from './layer_config.vue'
import { Keys } from '../feeds/keys'
import { mapState, mapGetters } from 'vuex'
import EditorStoreFactory from './store'
import Dropdown from '../components/dropdown.vue'
import SizeSelector from './components/sizes.vue'
import LayerComponents from './components'
import { capitalize } from './helpers'
import Api from './store/api/editor.api'
import PaletteSelector from './palettes.vue'
import { historyStore } from './store/plugins/history/history';
import LayerModel from './store/modules/layers/model';
import Moveable from './components/moveable.vue'
import Medialib from '../studio/components/medialib.vue'
import HeadlessLayerConf from './components/headless_layer_conf.vue'
import { mixin as clickaway } from 'vue-clickaway2';
import FeedEditor from './components/feed/editor.vue';
import Thumb from '../assets/thumb.vue'
import Sizebar from './sizebar.vue'
import LayerData from './layer_data.vue'
import AnimationTimeline from './animation_timeline.vue'
import Teleport from 'vue2-teleport';
import Panel from '../studio/components/panel.vue'

const BOTTOM_OFFSET = 50

export default {
  mixins: [clickaway],
  data() {
    return {
      _self: this,
      focussed: false,
      wide_submenu: false,
      history_store: historyStore,
      layer_components: LayerComponents.tree({ video: this.$props.canvas.video, addons: this.$props.feed ? this.$props.feed.addons : [] }),
      show_dropdown: false,
      show_filters: false,
      show_conditional_creation: false,
      show_variant_size_creation: false,
      exp: null,
      imp: null,
      editor_uid: `editor_${Math.random().toString(36).substring(7)}`,
      templates: [],
      tab: this.$props.is_studio ? 'data' : 'layers',
      status: "",
      zoom: 0.8,
      slider_zoom: 0.8,
      window_height: window.innerHeight - BOTTOM_OFFSET, //115 is the cummulative of the two headers used within the editor
      window_width: window.innerWidth,
      viewer_width: 600,
      viewer_height: 600,
      updating_canvas: false,
      key_listeners_attached: false,
      show_variants: (this.$props.is_studio && this.$props.canvas.video) || !this.$props.is_studio,
      video: {
        enabled: this.$props.canvas.video,
        scene_duration: 2,
        total_duration: 6,
        running: false,
        progress: 0,
        time: 0,
        controller: null
      },
      LayerModel,
      sizes: [
        ...this.$props.canvas.available_sizes.map(size => {
          return {
            name: size.name.split('_').join(' '),
            key: size.name,
            dimensions: size.render_dimensions,
            display_dimensions: size.display_dimensions,
            render_dimensions: size.render_dimensions,
            icon: `fab ${size.icon}`,
            enabled: size.enabled,
            aspect_icon: size.aspect_icon
          }
        })],
    }
  },
  components: {
    Medialib,
    LayerData,
    Canvas,
    LayerList,
    LayerConfig,
    Settings,
    Dropdown,
    Sizebar,
    InfiniteViewer,
    SizeSelector,
    PaletteSelector,
    Moveable,
    HeadlessLayerConf,
    FeedEditor,
    Thumb,
    AnimationTimeline,
    Teleport,
    Panel
  },
  props: {
    is_studio: { type: Boolean, required: false, default: false },
    on_save: { required: false, type: Function },
    on_variant_change: { required: false, type: Function, default: (v) => { } },
    animation_uid: { required: false, type: String, default: "adflw" },
    animation_complete: { required: false, type: Function },
    animation_progress: { required: false, type: Function },
    min_scene_duration: { required: false, type: Number, default: 2000 },
    headless: { type: Boolean, required: false, default: false },
    autosave: { type: Boolean, required: false, default: false },
    show_feed_selector: { type: Boolean, required: false, default: true },
    show_segments: { type: Boolean, required: false, default: true },
    show_templates: { Type: Boolean, required: false, default: true },
    show_import_export: { type: Boolean, required: false, default: false },
    show_sizes: { type: Boolean, required: false, default: false },
    save_redirect_url: { type: String, required: false, default: null },
    init_top_segment: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    brandkit: { type: Object, required: true, default: () => { } },
    size: { type: Object, default: { w: 600, h: 600 } },
    active_variant_id: { type: Number, required: false, default: null },
    editable: { type: Object, required: false },
    canvas: { Type: Object, required: false, default: {} },
    fonts: { Type: Array, required: false, default: [] },
    feed: { Type: Object, required: false, default: () => null },
    samples: { Type: Array, required: false, default: () => ({}) },
    show_data: { Type: Boolean, required: false, default: false },
    auto_create_sizes: { Type: Array, required: false, default: () => ([]) },
    on_focus: { Type: Function, required: false, default: () => { } },
    on_layer_select: { Type: Function, required: false, default: () => { } },
    event_target: { Type: Object, required: false, default: () => new EventTarget() },
    ext_zoom: { type: Number, required: false, default: null },
    scaleHeight: {
      type: Boolean,
      required: false,
      default() { return this.size.h === 0 }
    },
  },
  created() {
    let store = EditorStoreFactory.create()
    this.$root.$options.store = store
    this.$root.$store = store
    this.$store = store
    this.$store.dispatch('init', {
      canvas: this.$props.canvas,
      brandkit: this.$props.brandkit,
      feed: this.$props.feed,
      fonts: this.$props.fonts,
      autosave: this.$props.autosave,
      options: {
        event_target: this.$props.event_target,
        studio: this.$props.is_studio,
        save_redirect_url: this.$props.save_redirect_url,
        top_segment: this.$props.init_top_segment,
        on_save: this.$props.on_save,
        on_layer_select: this.$props.on_layer_select,
        auto_create_sizes: this.$props.auto_create_sizes,
        show_data: this.$props.show_data,
        active_variant_id: this.$props.active_variant_id,
      }
    })

    if (!this.headless) {
      this.attach_key_listeners({ remove: false })
    }

    // if(!this.headless)
    window.addEventListener("resize", this.set_window_sizes)
    this.$store.watch(() => store.getters['feeds/keys'], () => {
      this.$nextTick(() => {
        Keys.load()
      })
    })
    this.$store.subscribeAction((action, state) => {
      if (action.type === 'variants/create') {
        this.$nextTick(() => {
          this.auto_zoom()
        })
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.set_window_sizes)
    this.remove_nav_listeners()
    this.attach_key_listeners({ remove: true })
  },
  watch: {
    focussed(new_focus) {
      if (new_focus && this.headless) {
        this.attach_key_listeners({ remove: false })
      } else {
        this.attach_key_listeners({ remove: true })
      }
    },
    ext_zoom(new_zoom) {
      if (new_zoom) {
        this.zoom = new_zoom
      }
    },
    show_variants() {
      this.$nextTick(() => {
        this.auto_zoom()
      })
    },
    slider_zoom(new_zoom) {
      this.zoom = new_zoom
    },
    zoom(new_zoom) {
      this.$store.dispatch('emit', {
        type: 'zoom_changed', detail: { zoom: new_zoom }
      })
      this.$refs.viewer.scrollCenter()
    },
  },
  async mounted() {
    this.attach_nav_listeners()
    if (this.is_studio && !this.video.enabled) {
      this.templates = []
    } else if (this.is_studio) {
      this.templates = []
    } else {
      let templates = await Api.get_templates({ video: false })
      this.templates = templates.map(t => {
        t.template = JSON.parse(atob(t.template))
        return t
      })
    }

    $('#editor-save').click(() => this.$store.dispatch('save'))

    this.$store.subscribe(mutation => {
      if (mutation.type == 'start_save') {
        this.status = "Saving your changes"
        $(this.$refs.editor_status).trigger('open')
      }

      if (['layers/update', 'layers/delete', 'layers/create'].indexOf(mutation.type) > -1) {
        if (this.video.enabled && this.video.controller) {
          this.video.controller.reload(this['layers/list'])
        }
      }

      if (mutation.type == 'finish_save') {
        this.status = "All done!"
        setTimeout(() => {
          if (!this.save_redirect_url)
            $(this.$refs.editor_status).trigger('close')
          this.$emit('finish_save')
        }, 250)
      }

      if (mutation.type == 'update_canvas') {
        setTimeout(() => {
          this.auto_zoom()
          setTimeout(() => {
            this.updating_canvas = false
          }, 100)
        }, 75)
      }
    })

    this.$nextTick(() => {
      this.set_window_sizes()
      $('body').trigger('load-modal')
      $('#editor-export').off('closed').on('closed', () => {
        this.exp = ''
      })

      setTimeout(() => {
        if (this.video.enabled) {
          this.init_animation_controller()
        }
        let self = this
        $('.infinite-viewer-scroll-area').each(function () {
          $(this).click(function (event) {
            if (event.target !== this) return;
            self.setActiveLayer(null)
          })
        })
      }, 50)
    })
  },
  computed: {
    ...mapState({
      variants: state => state.variants.list,
      layers: state => state.layers,
      show_settings: state => state.options.show_settings,
      editor_canvas: state => state.canvas,
      stencils: state => state.stencils,
      feeds: state => state.feeds.list,
      sampling: state => state.feeds.sampling,
      s_canvas: state => state.canvas,
      segments: state => state.feeds.segments,
      history: state => state.history,
      dirty: state => state.dirty,
      options: state => state.options,
      project_id: state => state.project_id,
      ui_state: state => state.ui_state,
    }),
    store() {
      return this.$store
    },
    ...mapGetters(['variants/active', 'variants/list', 'feeds/keys', 'canvas_version',
      'feeds/samples', 'feeds/active_feed', 'top_segment', 'layers/all', 'layers/active_with_related',
      'feeds/segments', 'layers/active', 'layers/list', 'layers/all_variable']),
    comp_size() {
      if (typeof this['variants/active'].size === 'object') {
        return {
          w: this['variants/active'].size.display_dimensions[0],
          h: this['variants/active'].size.display_dimensions[1]
        }
      } else if (typeof this['variants/active'].size === 'string') {
        let size = this.sizes.find(s => s.key == this['variants/active'].size)
        return {
          w: size.display_dimensions[0],
          h: size.display_dimensions[1]
        }
      } else {
        return {
          w: this.s_canvas.width,
          h: this.s_canvas.height
        }
      }
    },
    active_variant_size() {
      return this.size_for(this['variants/active'])
    }
  },
  methods: {
    variant_changed(variant) {
      this.video.controller?.reset()
      this.on_variant_change(variant)
    },
    getActiveLayer() {
      return this.$store.getters['layers/active']
    },
    fix_layer_ordering(layers) {
      layers = JSON.parse(JSON.stringify(layers)).sort((a, b) => a.position - b.position);
      layers = layers.map((l, i) => (LayerModel.factory({ ...l, position: (i + 1) * 10 })));
      return layers;
    },
    focus(bool = true) {
      this.$props.on_focus()
      this.focussed = bool
      this.video.controller?.reset()
    },
    update_canvas_feed(feed, row) {
      this.$store.commit('update_canvas_feed', feed)
    },
    size_for(variant) {
      if (typeof variant.size === 'object') {
        return {
          w: variant.size.render_dimensions[0],
          h: variant.size.render_dimensions[1],
          icon: variant.size.icon,
          aspect_icon: variant.size.aspect_icon
        }
      } else if (typeof variant.size === 'string') {
        let size = this.sizes.find(s => s.key == variant.size)

        return {
          w: size.render_dimensions[0],
          h: size.render_dimensions[1],
          icon: size.icon,
          aspect_icon: size.aspect_icon
        }
      } else {
        return {
          w: this.s_canvas.width,
          h: this.s_canvas.height
        }
      }
    },
    has_variant_for(size) {
      return this.variants.filter(v => !v.deleted).find(v => {
        if (typeof v.size === 'object')
          return v.size.name === size.key
        else
          return v.size === size.key
      })
    },
    seek(val) {
      this.video.controller.seek((val / 100) * this.video.controller.duration())
    },
    play() {
      this.video.controller.play()
    },
    pause() {
      this.video.controller.pause()
    },
    init_animation_controller() {
      setTimeout(() => {
        this.video.controller = (
          this.$refs
            .canvas
            .init_animation_controller(this.min_scene_duration,
              this.min_scene_duration,
              (this.feed ? this['feeds/samples'].data : (this.samples.data ? this.samples.data : this.s_canvas.feed.data)), {
              update_layer: this.updateLayer,
              begin: (anim) => { this.video.running = true },
              complete: (anim) => {
                this.video.running = false
                this.video.progress = 0
                this.video.time = 0
              },
              update: (anim) => {
                this.video.progress = Math.round(anim.progress)
                this.video.time = anim.currentTime
              }
            }))
        this.video.controller.reset()
      }, 25)
    },
    undo() {
      this.$store.dispatch('undo')
    },
    redo() {
      this.$store.dispatch('redo')
    },
    attach_key_listeners({ remove = false }) {
      console.log(`attach_key_listeners called with remove=${remove}`);
      if (remove === false && this.key_listeners_attached === false) {
        $(document).on('copy', this.copy_listener)
        $(document).on('paste', this.paste_listener)
        $(document).on('cut', this.cut_listener)
        $(document).on('keydown', this.key_listeners)
        this.key_listeners_attached = true
      } else if (remove === true) {
        $(document).off('copy', this.copy_listener)
        $(document).off('paste', this.paste_listener)
        $(document).off('cut', this.cut_listener)
        $(document).off('keydown', this.key_listeners)
        if (!this.headless)
          navigator.clipboard.writeText(``)
        this.key_listeners_attached = false
      }
    },
    copy_listener() {
      let active_with_related = this['layers/active_with_related'];
      if (this['layers/active'] && (
        document.activeElement.className.includes('layer') ||
        document.activeElement.tagName === 'BODY' ||
        document.activeElement.tagName === 'DIV')) {
        let layers = JSON.stringify(active_with_related.map(l => ({ ...l, id: null })))
        navigator.clipboard.writeText(`editor_layer~${layers}`)
        create_notification('Layer added to clipboard', this['layers/active'].text, 'success')
      } else {
        console.log('No active layer to copy')
      }
    },
    cut_listener() {
      if (this['layers/active']) {
        this.copy_listener()
        this.deleteLayer(this['layers/active'])
      }
    },
    key_listeners(e) {
      let active_element_tag = null
      if (document.activeElement)
        active_element_tag = document.activeElement.tagName.toLowerCase()

      if ((e.key == " " ||
        e.code == "Space" ||
        e.keyCode == 32) && active_element_tag !== 'input' && active_element_tag !== 'textarea'
      ) {
        e.preventDefault()
      }
      if (e.keyCode == 90 && (e.metaKey || e.ctrlKey) && !!e.shiftKey) {
        this.redo()
      } else if (e.keyCode == 90 && (e.metaKey || e.ctrlKey)) {
        this.undo()
      } else if (this['layers/active'] && (e.keyCode == 8 || e.keyCode == 46)) {
        if (active_element_tag) {
          if (active_element_tag !== 'input' && active_element_tag !== 'textarea') {
            this.deleteLayer(this['layers/active'])
          }
        } else {
          this.deleteLayer(this['layers/active'])
        }
      } else if (e.keyCode == 32 && this.video.enabled) {
        let active_element_tag = document.activeElement.tagName.toLowerCase()
        if (active_element_tag !== 'input' && active_element_tag !== 'textarea') {
          if (this.video.controller)
            this.video.controller.playPause()
        }
      }
    },
    async paste_listener() {
      const pasta = await navigator.clipboard.readText()
      let pasted = false
      let notification_text = ""
      if (pasta.split('~').length === 0)
        return
      switch (pasta.split('~')[0]) {
        case 'editor_layer':
          let layers = JSON.parse(pasta.split('~')[1])
          if (this.canvas_version > 1) {
            this.$store.dispatch('layers/create_from_list', layers)
          } else {
            layers.forEach(layer => {
              this.$store.dispatch('layers/create', layer)
            })
          }
          pasted = true
          notification_text = layers[0].text
          break;
        default:
          null
      }
      if (pasted)
        create_notification('Layer pasted from clipboard', notification_text, 'success')
    },
    set_window_sizes(e) {
      this.window_height = window.innerHeight - BOTTOM_OFFSET
      this.window_width = window.innerWidth
      this.$nextTick(() => {
        this.auto_zoom()
      })
    },
    pinch(e) {
      let zoom = Math.max(0.1, e.zoom)
      if (zoom <= 3) {
        this.zoom = zoom
        this.slider_zoom = zoom
      }
    },
    set_zoom(e) {
      this.slider_zoom = Math.max(0.1, e.target.value / 100).toFixed(2)
    },
    auto_zoom(canvas_width = this.comp_size.w,
      canvas_height = this.comp_size.h,
      window_width = window.innerWidth,
      window_height = window.innerHeight - BOTTOM_OFFSET,
      zoom = 1.0) {

      // Determine the larger dimension for canvas and window
      let canvas_max = Math.max(canvas_width, canvas_height);
      let window_max = Math.max(window_width, window_height);
      let window_min = Math.min(window_width, window_height);

      let FACTOR = 1.5;
      if (canvas_width === canvas_height) {
        FACTOR = FACTOR;
      } else if (canvas_width > canvas_height) {
        FACTOR = 2.5;
      } else {
        FACTOR = 1.4;
      }

      // Calculate dynamic zoom step based on window width
      const baseZoomStep = 0.01;
      const minWindowWidth = 100; // Example threshold for smaller windows
      const dynamicZoomStep = window_width < minWindowWidth ? baseZoomStep * 2 : baseZoomStep;

      // Adjust zoom so that the canvas fits within the window dimensions
      while (canvas_max * zoom * FACTOR > window_max ||
        (canvas_width * zoom * FACTOR > window_width) ||
        (canvas_height * zoom * FACTOR > window_height)) {
        zoom -= dynamicZoomStep;
      }

      while (canvas_max * zoom * FACTOR < window_min &&
        (canvas_width * zoom * FACTOR < window_width) &&
        (canvas_height * zoom * FACTOR < window_height)) {
        zoom += dynamicZoomStep;
      }
      this.zoom = zoom;
      this.slider_zoom = zoom;
    },

    attach_nav_listeners() {
      window.addEventListener('beforeunload', this.before_unload)
    },

    remove_nav_listeners() {
      window.removeEventListener('beforeunload', this.before_unload)
    },

    before_unload(e) {
      if (this.dirty) {
        e.preventDefault()
        e.returnValue = ''
      }
    },

    async set_top_segment(segment) {
      this.show_filters = false
      await this.$store.dispatch('set_top_segment', segment)
      await this.$store.dispatch('feeds/sample', {
        feed_id: this['feeds/active_feed'].complex_identifier,
        segments: [this['variants/active'].segment_id]
      })
    },

    get_layer(layer) {
      return this.$store.getters['layers/list'].find(l => l.id == layer.id)
    },

    set_variant_segment(el) {
      this.updateVariant({
        name: $(el).find("option:selected").text(),
        segment_id: el.value
      })
    },

    export_template() {
      this.$store.dispatch('export').then(exp => {
        this.exp = exp
        $('#editor-export').trigger('open')
      })
    },

    import_template(template = null, base64 = true) {
      this.$store.dispatch('import', {
        template: template || this.imp,
        base64
      }).then(() => {
        $('#editor-import').trigger('close')
      })
    },

    save() {
      this.$store.dispatch('save')
    },

    emit_anim_controller_init(controller) {
      this.$store.dispatch('emit', {
        type: 'animation_controller_init', detail: {
          controller,
          canvas_id: this.editor_canvas.id
        }
      })
    },

    add_variant({ segment, size }) {
      this.$store.dispatch('variants/create', { segment, size: this.canvas.available_sizes.find(s => s.name == size) })
      this.show_conditional_creation = false
      this.show_variant_size_creation = false
      setTimeout(() => {
        this.auto_zoom()
        this.unsetActiveLayer()
        // this.init_animation_controller()
      }, 1)
    },

    set_ui_state(state) {
      this.$store.dispatch('set_state', state)
    },

    edit_variant(variant) {
      this.setActiveVariant(variant)
      this.set_ui_state('STATE_PICK_VARIANT_SIZE')
    },

    cap(string) {
      return capitalize(string)
    },

    delete_variant(variant_id) {
      this.$store.dispatch('variants/delete', variant_id)
      setTimeout(() => {
        this.auto_zoom()
        // this.init_animation_controller()
        this.unsetActiveLayer()
        this.set_ui_state('STATE_DEFAULT')
      }, 1)
    },

    build_key(key) {
      return `{{${key}}}`
    },

    toggle_settings() {
      this.$store.dispatch('toggle_settings', !this.show_settings)
    },

    update_editor_canvas(canvas) {
      $('#canvassable_name').text(canvas.name)
      this.updating_canvas = true
      this.$store.dispatch('update_canvas', canvas)
      if (!this.studio) {
        this['variants/list'].forEach(variant => {
          this.updateVariant({ ...variant, size: canvas.size })
        })
      }
      this.set_ui_state('STATE_DEFAULT')
    },

    sample_feed(event) {
      this.$store.dispatch('feeds/sample', { feed_id: event.target.value })
    },
    next_sample() {
      this.$store.dispatch('feeds/next_sample')
    },

    previous_sample() {
      this.$store.dispatch('feeds/previous_sample')
    },

    async addLayer(config = {}) {
      this.show_dropdown = false
      await this.$store.dispatch('layers/create', config)
    },

    deleteLayer(layer) {
      this.$store.dispatch('layers/delete', layer)
    },

    updateLayer(layer) {
      this.$store.dispatch('layers/update', layer)
    },

    on_layer_visibility_change(layer, mode = LayerModel.VISIBILITY_MODES.ONLY_THIS_VISIBLE) {
      this.$store.dispatch('layers/update_visibility', { layer, mode })
    },

    update_moveable(action, value) {
      this.$store.dispatch(action, value)
    },

    unsetActiveLayer() {
      this.$store.dispatch('layers/activate', null)
      if (this.is_studio)
        this.tab = 'data'
    },

    setActiveLayer(layer, dbclick = false) {
      if (layer) {
        this.tab = 'layers'
      } else if (this.is_studio) {
        this.tab = 'data'
      }
      if (this.video.controller) {
        // this.video.controller.reset()
        this.video.progress = 0
      }
      // if(layer && layer.config.group_id && !dbclick){
      //   this.$store.dispatch('layers/activate', this._self['layers/list'].find(l => l.id == layer.config.group_id))
      // } else {
      this.$store.dispatch('layers/activate', layer)
      // }
    },

    updateLayerOrder(layers) {
      this.$store.dispatch('layers/order', layers)
    },

    setActiveVariant(variant) {
      this.$store.dispatch('variants/activate', variant)

      this.$nextTick(() => {
        this.auto_zoom()
        this.video.controller.reset()
        this.unsetActiveLayer()
      })
    },

    updateVariant(changes) {
      this.$store.dispatch('variants/update', changes)
      this.$nextTick(() => {
        this.auto_zoom()
        // this.init_animation_controller()
        this.unsetActiveLayer()
      })
    },

    set_palette(palette) {
      this.$store.dispatch('palette', palette)
    }
  }
}

</script>
