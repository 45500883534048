<template>
  <div class="review__stars" :style="{ 'text-align': 'center', 'align-items': 'center' }">

    <div class="width: 10%; text-align: center;" v-if="sample['adflow_review_provider'] == 'feedback_company'">
      <img width="100%" src="https://cdn.adflow.io/public/7a1f7c6f-7388-4c65-9ad2-acea0e962d36.png">
    </div>

    <Stars :score="product_score" :score_symbol="product_score_symbol" :range="range" :layer="layer"
      :animation_uid="animation_uid" />

    <div :style="{ fontSize: layer.fontSize * .75 + 'px', fontFamily: layer.font }"
      v-if="this.layer.config['product_score_show_n_reviews'] == 'true' || this.layer.config['product_score_show_n_reviews'] == true">
      {{ number_of_reviews }} {{ number_of_reviews == 1 ? 'review' : 'reviews' }}
    </div>

  </div>
</template>

<script>
import { mustache } from '../../lib/parsers'
import { apply } from './tags'
import Stars from './components/stars.vue'

export default {
  props: ['layer', 'samples', 'disabled', 'brandkit', 'animation_uid'],
  components: { Stars },
  data() {
    return {
      range: []
    }
  },
  computed: {
    number_of_reviews() {
      return mustache.fill_in(this.layer.config['product_score_n_reviews'] || "", this.sample)
    },
    sample: function () {
      return { ...this.samples.data[this.samples.indice], brandkit: { ...this.brand } } || {}
    },
    brand: function () {
      let brand = Object.keys(this.$props.brandkit).length > 0 ? this.$props.brandkit : (this.$root.$store ? this.$root.$store.state.brandkit : {})
      let placeholder_brand = {}
      if (this.samples.data[this.samples.indice] && this.samples.data[this.samples.indice].brandkit) {
        placeholder_brand = this.samples.data[this.samples.indice].brandkit
      }

      let result = { ...brand }
      for (let key in brand) {
        if (!brand[key] && placeholder_brand[key]) {
          result[key] = placeholder_brand[key]
        }
      }

      return result;
    },
    product_score() {
      if (this.sample && this.layer) {
        try {
          let base = this.layer.config['product_score_base'] || 10
          let limit = this.layer.config['product_score_limit'] || 5
          let factor = base / limit
          return parseFloat(mustache.fill_in(this.layer.text || "", this.sample)) / factor
        } catch (e) {
          return 0.0
        }
      }
      return 0.0
    },
    product_score_symbol() {
      if (this.sample && this.layer) {
        try {
          return mustache.fill_in(this.layer.config['product_score_symbol'] || "", this.sample)
        } catch (e) {
          return null
        }
      }
      return null
    },
  },
  watch: {
    layer: {
      immediate: true,
      deep: true,
      handler(layer) {
        if (layer)
          this.range = Array(parseInt(layer.config.product_score_limit)).fill(undefined).map((v, idx) => idx)
      }
    }
  },
  methods: {
    getValue(string) {
      if (!string) return null
      try {
        if (this.samples && this.samples.data.length > 0) {
          return this.parse(mustache.fill_in(string, this.samples.data[this.samples.indice]))
        } else {
          return this.parse(string)
        }
      } catch (e) {
        return ' '
      }
    },
    parse(str) {
      return apply(str, this.sample, { ...this.layer.config })
    },
  }
}
</script>
