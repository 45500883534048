<template>
  <div :style="container_styles" class="layer_image" :id="`image_layer_${animation_uid}_${layer.id}`">
    <img :style="this.styles" :src="clean_source" v-if="!has_focus_points" @error="register_error" />
  </div>
</template>

<script>
export default {
  props: {
    layer: {
      type: Object,
      required: true
    },
    samples: {
      type: Object,
      required: false,
      default: () => {
        return { data: [], indice: 0 }
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    on_error: {
      type: Function,
      required: false,
      default: () => { }
    },
    brandkit: {
      type: Object,
      required: false,
      default: () => { }
    },
    animation_uid: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {
      source: '/sample_image.png'
    }
  },
  computed: {
    images() {
      if (this.sample) {
        const images = this.sample[this.layer.config['additional_images_field']]
        if (typeof images === 'string') {
          return images.split(',').filter(Boolean)
        } else {
          return (typeof images == 'object') ? images : [images]
        }
      } else {
        return []
      }
    },
    image() {
      let index = this.layer.config['additional_images_index']
      if (this.images && index > this.images.length - 1)
        index = this.images.length - 1
      return this.images ? this.images[index] : ''
    },
    clean_source() {
      return this.source
    },
    styles() {
      return {
        'object-fit': this.layer.config['object_fit'] || 'contain',
        'object-position': `${this.layer.config.object_position || 'center'}`,
        width: '100%',
        height: '100%'
      }
    },
    background_position() {
      let y = Math.abs(this.focus_points.y) / 2 * 100
      let x = Math.abs(this.focus_points.x) / 2 * 100
      if (this.focus_points.y < 0) {
        y += 50
      } else {
        y = 50 - Math.abs(this.focus_points.y) * 50
      }
      if (this.focus_points.x > 0) {
        x += 50
      } else {
        x = 50 - Math.abs(this.focus_points.x) * 50
      }
      return `${x}% ${y}%`
    },
    container_styles() {
      if (this.has_focus_points) {
        return {
          width: `${this.layer.width}px`,
          height: `${this.layer.height}px`,
          'background-image': `url(${this.clean_source})`,
          'background-size': 'cover',
          'background-position': this.background_position,
          '-webkit-backface-visibility': 'hidden',
          '-moz-backface-visibility': 'hidden',
          '-ms-backface-visibility': 'hidden',
          '-webkit-transform': 'translate3d(0, 0, 0)'
        }
      } else {
        return { width: `${this.layer.width}px`, height: `${this.layer.height}px` }
      }
    },
    sample: function () {
      let sample = this.samples.data[this.samples.indice]
      let brand = { ...this.brand }
      if (sample.brandkit && sample.brandkit.logos && sample.brandkit.logos.length > 0 && sample.brandkit.logos.every(l => !!l)) {
        brand.logos = sample.brandkit.logos
      }
      return { ...sample, brandkit: brand } || {}
    },
    brand: function () {
      let brand = Object.keys(this.$props.brandkit).length > 0 ? this.$props.brandkit : (this.$root.$store ? this.$root.$store.state.brandkit : {})
      let placeholder_brand = {}
      if (this.samples.data[this.samples.indice] && this.samples.data[this.samples.indice].brandkit) {
        placeholder_brand = this.samples.data[this.samples.indice].brandkit
      }

      let result = { ...brand }
      for (let key in brand) {
        if (!brand[key] && placeholder_brand[key]) {
          result[key] = placeholder_brand[key]
        }
      }

      return result;
    },
    focus_points() {
      try {
        let link = new URL(this.source)
        let focus_x = link.searchParams.get('focus_x')
        let focus_y = link.searchParams.get('focus_y')
        return { x: focus_x, y: focus_y }
      } catch (e) {
        return { x: 0, y: 0 }
      }
    },
    has_focus_points() {
      try {
        let link = new URL(this.source)
        let focus_x = link.searchParams.get('focus_x')
        let focus_y = link.searchParams.get('focus_y')
        return !!(focus_x && focus_y)
      } catch (e) {
        return false
      }
    },
  },
  watch: {
    image: {
      handler(newval, oldval) {
        if (this.is_valid_url(newval)) {
          this.source = newval
        } else {
          this.source = '/sample_image.png'
          this.on_error({ 'type': 'invalid', 'value': newval })
        }
      },
      immediate: true
    }
  },
  methods: {
    register_error(e) {
      console.error(e)
      this.source = '/sample_image.png'
      this.on_error({ 'type': 'load', 'value': e.target.src })
    },
    is_valid_url(string) {
      // The pattern checks for the presence of "http://" or "https://",
      // followed by any characters, and ending in a valid image extension
      // possibly followed by query parameters. The image extension part is optional.
      const urlPattern = /^https?:\/\/.*(\.(jpeg|jpg|gif|png|webp)(\?.*)?)?$/i;
      return urlPattern.test(string);
    }
  }
}
</script>
