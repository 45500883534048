export const UPDATE = 'update'
export const CREATE = 'create'
export const DELETE = 'delete'
export const INIT = 'init'
export const ACTIVATE = 'activate'
export const HISTORY = 'history'
export const IMPORT = 'import'
export const UNDO = 'undo'
export const REDO = 'redo'
export const UPDATE_LINKED = 'update_linked'
export const UPDATE_VISIBILITY = 'update_visibility'

export const START_SAVE = 'start_save'
export const FINISH_SAVE = 'finish_save'

export const CREATE_REMOTE = 'c_remote'
export const UPDATE_REMOTE = 'u_remote'
export const DELETE_REMOTE = 'd_remote'

export const ROTATE = 'rotate'
export const SCALE = 'scale'
export const RESIZE = 'resize'
export const WARP = 'warp'
export const PALETTE = 'palette'
export const TRANSLATE = 'translate'
