import {
  CREATE,
  DELETE,
  UPDATE,
  UPDATE_LINKED,
  INIT,
  ACTIVATE,
  CREATE_REMOTE,
  UPDATE_REMOTE,
  DELETE_REMOTE,
  UNDO
} from '../mutation_types'
import {
  TRANSLATE, ROTATE, RESIZE, ADD, REMOVE, WARP, PALETTE
} from './mutation_types'
import Layer from './model'

export default {
  [CREATE](state, layer) {
    state.list = [layer, ...state.list]
  },

  // This function way to complicated for what it does. But for now we're leaving it that way
  // At first it just assigned randomized background colors to layers based on the given palette
  // Now we scan for equal colors across text and backgrounds and group them so they
  // get assigned the same color

  // Contrast calc for color backgrounds
  // const contrast = chroma.contrast(layer.color, background)
  // const color = layer.color
  //  if (contrast < 4.5){
  //    color = chroma(layer.color).darken()
  //  }
  [PALETTE](state, { layers, palette }) {
    const color_attribs = ['color', 'background']
    const color_groups = color_attribs.reduce((attr_memo, attr) => {
      attr_memo[attr] = layers.reduce((memo, layer) => {
        if (!layer.locked_attributes.includes(attr)) {
          if (!memo[layer[attr]]) memo[layer[attr]] = { layers: [], palette_color: null }
          if (!memo[layer[attr]].layers.find((l) => l.id === layer.id)) {
            memo[layer[attr]].layers.push(layer)
          }
        }
        return memo
      }, {})

      return attr_memo
    }, {})

    let used_colors = []

    function gen_color(used_colors) {
      palette = [...palette].filter((c) => !used_colors.includes(c))
      const color = palette[Math.floor(Math.random() * palette.length)]
      used_colors.push(color)
      return color
    }

    const new_colors = Object.keys(color_groups)
      .flatMap((attr) => Object.keys(color_groups[attr]))
      .filter((v, i, a) => a.indexOf(v) === i)
      .reduce((memo, group) => {
        memo[group] = gen_color(used_colors)
        return memo
      }, {})

    layers = Object.keys(color_groups).flatMap((attr) => {
      used_colors = []
      return Object.keys(color_groups[attr]).flatMap((group) => {
        const color = new_colors[group]
        return color_groups[attr][group].layers.map((layer) => {
          if (layer[attr] !== 'transparent') layer[attr] = color
          return layer
        })
      })
    })

    state.list = state.list.map((l) => {
      const x = layers.find((layer) => l.id === layer.id)
      if (x) {
        l = { ...l, ...x, dirty: true }
        return Layer.factory(l)
      }
      return l
    })
  },

  [ACTIVATE](state, layer) {
    state.active = layer ? layer.id : null
  },

  [UPDATE](state, layer) {
    state.list = state.list.map((l) => {
      let props = {}

      if (l.id === layer.id) {
        props = { ...l, ...layer }
      }

      return new Layer({ ...l, ...props })
    })
  },

  [UPDATE_LINKED](state, { linked_layers, updated_linked_layers }) {
    state.list = state.list.map((l) => {
      if (linked_layers.map((l) => l.id).includes(l.id)) {
        return updated_linked_layers.find((ul) => ul.id === l.id)
      }
      return l
    })
  },

  [DELETE](state, layer) {
    state.list = state.list.map((l) => {
      if (layer.id === l.id) {
        l.deleted = true
      }
      return l
    })
  },

  [UNDO](state, { history }) {
    const new_id = history.new_value.id // id_map[history.new_value.id] || history.new_value.id
    switch (history.type.split('/')[1]) {
      case CREATE:
        state.list = state.list.map((l) => {
          if (l.id === new_id) {
            l.deleted = true
          }
          return l
        })
        break;
      case UPDATE:
        state.list = state.list.map((l) => ((l.id === new_id) ? history.old_value : l))
        break;
      case DELETE:
        state.list = state.list.map((l) => {
          if (l.id === new_id) {
            l.deleted = false
          }
          return l
        })
        break;
      default:
        break;
    }
    state.list = state.list.filter(Boolean)
    return state
  },

  [CREATE_REMOTE](state, { old_val, new_val }) {
    state.list = state.list.map((l) => {
      if (old_val.id === l.id) {
        l = Layer.factory({ ...l, ...new_val })
      }
      return l
    })
  },

  [UPDATE_REMOTE](state, { old_val, new_val }) {
    state.list = state.list.map((l) => {
      if (old_val.id === l.id) {
        l = Layer.factory({ ...l, ...new_val })
      }
      return l
    })
  },

  [DELETE_REMOTE](state, { old_val }) {
    state.list = state.list.filter((l) => l.id !== old_val.id)
  },

  [ADD](state, layers) {
    state.list = [...state.list, ...layers]
  },

  [REMOVE](state, layers) {
    const ids = layers.map((l) => l.id)
    state.list = state.list.map((l) => {
      if (ids.includes(l.id)) {
        l.deleted = true
      }
      return l
    })
  },

  [INIT](state, layers) {
    state.list = layers.map((layer) => new Layer(layer))
    state.translations = layers.map((layer) => ({
      layer,
      translation: layer.translation
    }))
    state.rotations = layers.map((layer) => ({
      layer,
      rotation: layer.rotation
    }))
  },

  [ROTATE](state, { layer, transform }) {
    state.list = state.list.map((l) => {
      if (l.id === layer.id) {
        l = {
          ...l,
          ...layer,
          config: { ...l.config, transform }
        }
      }
      return l
    })
  },

  [WARP](state, { layer, transform }) {
    state.list = state.list.map((l) => {
      if (l.id === layer.id) {
        l = {
          ...l,
          ...layer,
          config: { ...l.config, transform }
        }
      }
      return l
    })
  },

  [RESIZE](state, {
    width, height, transform, layer
  }) {
    state.list = state.list.map((l) => {
      if (l.id === layer.id) {
        l = {
          ...l, config: { ...l.config, transform }, width, height,
        }
      }
      return l
    })
  },

  [TRANSLATE](state, { layer, transform }) {
    state.list = state.list.map((l) => {
      if (l.id === layer.id) {
        l = {
          ...l,
          ...layer,
          config: { ...l.config, transform }
        }
      }
      return l
    })
  }
}
