<template>
  <div>
    <TextConfig :layer="layer"
                :fonts="fonts"
                :mediaFolder="mediaFolder"
                :onUpdate="onUpdate"
                :palette="palette"
                :nightswatch="nightswatch"
                :inputName="'Price'"/>


    <Collapseable :label="`Strikethrough`">
      <div class="form-group" style="font-size:14px;">
        <div class="button-group--select">
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              strikethrough_angle: 0,
              strikethrough: false
            })
          }">
            <input type="radio" v-model="strikethrough" :value="false" name="strikethrough_angle">
            <label><span>
              €10{{layer.config.decimal}}00
            </span></label>
          </div>
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              strikethrough_angle: 10,
              strikethrough: true
            })
          }">
            <input type="radio" v-model="strikethrough_angle" value="10" name="strikethrough_angle">
            <label><span class="editor__strikethrough-diagonal"
                style="--price-strikethrough-deg: 10deg; --price-strikethrough-width: 1px; --price-strikethrough-color: black; ">
              €10{{layer.config.decimal}}00
            </span></label>
          </div>
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              strikethrough_angle: 1,
              strikethrough: true
            })
          }">
            <input type="radio" v-model="strikethrough_angle" value="1" name="strikethrough_angle">
            <label><span class="editor__strikethrough-diagonal"
                style="--price-strikethrough-deg: 1deg; --price-strikethrough-width: 1px; --price-strikethrough-color: black; ">
            €10{{layer.config.decimal}}00
          </span></label>
          </div>
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              strikethrough_angle: 170,
              strikethrough: true
            })
          }">
            <input type="radio" v-model="strikethrough_angle" value="170" name="strikethrough_angle">
            <label><span class="editor__strikethrough-diagonal"
                style="--price-strikethrough-deg: 170deg; --price-strikethrough-width: 1px; --price-strikethrough-color: black; ">
            €10{{layer.config.decimal}}00
          </span></label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Color</label>
        <ColorField 
          :show_dynamic="true" 
          :element="element" 
          :element_key="'color'" 
          :palette="palette"/>
      </div>
    </Collapseable>

    <Collapseable :label="`Price formatting`">
      <div class="form-group" style="font-size:14px;">
        <div>
          <input type="checkbox" :checked="hide_separator" v-on:change="e => {
            updateLayerConfig({separator: e.target.checked ? '' : layer.config.separator || '.'})
          }">
          Hide separator?
        </div>
        <div class="button-group--select">
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              separator: hide_separator ? '' : '.',
              decimal: ','
            })
          }">
            <input type="radio" v-model="sep_dec" value="EU" name="sep_dec">
            <label><span>€1{{hide_separator ? '' : '.'}}000,00</span></label>
          </div>
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              separator: hide_separator ? '' : ',',
              decimal: '.'
            })
          }">
            <input type="radio" v-model="sep_dec" value="US" name="sep_dec">
            <label><span>€1{{hide_separator ? '' : ','}}000.00</span></label>
          </div>
        </div>
      </div>

      <div class="form-group" style="font-size:14px;">
        <label>Show symbol?</label>
        <div class="button-group--select">
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              show_symbol: true,
            })
          }">
            <input type="radio" v-model="show_symbol" value="true" name="show_symbol">
            <label><span>€10{{layer.config.decimal}}00</span></label>
          </div>
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              show_symbol: false,
            })
          }">
            <input type="radio" v-model="show_symbol" value="false" name="show_symbol">
            <label><span>10{{layer.config.decimal}}00</span></label>
          </div>
        </div>
      </div>

      <div class="form-group" style="font-size:14px;">
        <label>Truncate zeros?</label>
        <div class="button-group--select">
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              truncate_trailing_zeros: false,
            })
          }">
            <input type="radio" v-model="truncate_trailing_zeros" value="false" name="truncate_zeros">
            <label><span>€10{{layer.config.decimal}}00</span></label>
          </div>
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              truncate_trailing_zeros: true,
            })
          }">
            <input type="radio" v-model="truncate_trailing_zeros" value="true" name="truncate_zeros">
            <label><span>€10{{layer.config.decimal}}-</span></label>
          </div>
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              truncate_trailing_zeros: 'hide_if_zero',
            })
          }">
            <input type="radio" v-model="truncate_trailing_zeros" value="hide_if_zero" name="truncate_zeros">
            <label><span>€10</span></label>
          </div>
        </div>
      </div>

      <div class="form-group" style="font-size:14px;">
        <label>Superscript?</label>
        <div class="button-group--select">
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              superscript: false,
            })
          }">
            <input type="radio" v-model="superscript" value="false" name="superscript">
            <label><span>€10{{layer.config.decimal}}45</span></label>
          </div>
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              superscript: true,
            })
          }">
            <input type="radio" v-model="superscript" value="true" name="superscript">
            <label><span>€10{{layer.config.decimal}}<sup>45</sup></span></label>
          </div>
        </div>
      </div>
    </Collapseable>
  </div>
</template>

<script>
import TextConfig from './text.vue'
import Collapseable from '../components/collapseable.vue'
import ColorField from '../../feeds/fields/color.vue'

export default  {
  props: ['layer', 'fonts', 'mediaFolder', 'onUpdate', 'palette', 'nightswatch'],
  data(){
    return {
      sep_dec:`EU`,
      show_symbol: false,
      strikethrough: false,
      truncate_trailing_zeros: true,
      superscript: false,
      separator: '.',
      decimal: ',',
      hide_separator: false,
      strikethrough_angle: 1,
      element: {
        color: {hex: this.layer.config.strikethrough_color},
      }
    }
  },
  components: {TextConfig, Collapseable, ColorField},
  mounted(){
    this.init()
  },
  watch: {
    element: {
      deep: true,
      handler(newVal){
        this.updateLayerConfig({strikethrough_color: newVal.color.hex8})
      }
    },
    layer: {
      immediate: true,
      deep: true,
      handler(newLayer){
        let conf = newLayer.config
        if((conf.separator === '' || conf.separator === '.') && newLayer.config.decimal === ','){
          this.sep_dec = 'EU'
        } else {
          this.sep_dec = 'US'
        }
        this.show_symbol = newLayer.config.show_symbol
        this.truncate_trailing_zeros = newLayer.config.truncate_trailing_zeros
        this.superscript = newLayer.config.superscript
        this.separator = newLayer.config.separator
        this.decimal = newLayer.config.decimal
        this.hide_separator = conf.separator === ''
        this.strikethrough = newLayer.config.strikethrough
        this.strikethrough_angle = newLayer.config.strikethrough_angle
      }
    }
  },
  methods: {
    updateLayerConfig(conf){
      this.updateLayer('config', {...this.layer.config, ...conf})
    },
    updateLayer(key, value){
      const layer = {...this.layer, [key]: value}
      this.$props.onUpdate(layer)
    },
    setFont(fontId){
      const font = this.fonts.find(f=>f.id === parseInt(fontId))
      this.$props.onUpdate({...this.layer,
                            font: font ? font.filename : '',
                            font_id: parseInt(fontId)})
    },
    align(align){
      this.$props.onUpdate({...this.layer, align})
    },
    init(){
      let opts = ['separator', 'decimal', 'show_symbol', 'truncate_trailing_zeros', 'superscript']
      if(!opts.every(opt => Object.keys({...this.layer.config}).includes(opt))){
        let layer = {
          ...this.layer,
          config: {
            ...this.layer.config,
            ...{
              separator: '.',
              decimal: ',',
              truncate_trailing_zeros: false,
              show_symbol: true,
              superscript: false
            }
          }
        }
        this.$props.onUpdate(layer)
      }
    }
  }
}
</script>
