<template>
  <!-- style="position: absolute;inset: -->

  <div class="studio__player__preview" v-if="!render_mode && variant"
    :style="{ zIndex: z_index, opacity: active_playing || (active && !playing) ? 1 : 0 }" :id="`scene_${scene.id}`"
    :class="{ [`studio__player__preview--${variant.size.name}`]: true }" @click.self="unset_layer"
    :key="`scene_${scene.id}_${variant.id}`">
    <Editor :canvas="{ ...scene.canvas, variants: scene.canvas.variants, layers: layers }" :brandkit="brandkit"
      :active_variant_id="variant.id" :fonts="fonts || []" :headless="true" :autosave="true"
      :disabled="z_index !== active_z_index" ref="editor" :is_studio="true" :size="a_size" :on_hover_play="false"
      :on_focus="e => on_focus(e)" :on_layer_select="on_layer_select"
      :on_save="(type, method, object, variant) => on_save(type, method, object, variant)"
      :on_variant_change="variant => set_size(variant)" :animation_uid="`adflw_scene_${scene.id}${anim_uid}`"
      :animation_progress="e => on_anim_progress(scene, e)" :animation_complete="e => on_anim_complete(scene, e)"
      :min_scene_duration="scene.min_scene_duration" :samples="{ data: scene_data(), indice: 0 }"
      :event_target="event_target" :ext_zoom="global_zoom">
    </Editor>
  </div>
  <div v-else-if="variant && !active">
    <Canvas :layers="layers" :force_default_brandkit="true" :background="scene.canvas.background_image"
      :background_color="scene.canvas.background_color" :brandkit="brandkit" :size="size" :is_animated="true"
      :animation_uid="`adflw_scene_${scene.id}${anim_uid}`" :style="{ zIndex: z_index, position: 'absolute' }"
      :animation_progress="e => on_anim_progress(scene, e)" :animation_complete="e => on_anim_complete(scene, e)"
      :min_scene_duration="scene.min_scene_duration" :disabled="true" :render_only="true" :fonts="fonts || []"
      ref="canvas" :samples="{ data: scene_data(), indice: 0 }" />
  </div>

</template>

<script>
import Editor from '../../image-editor/editor.vue'
import Canvas from '../../image-editor/canvas.vue'
import { mapState } from 'vuex'

export default {
  components: { Canvas, Editor },
  props: {
    size: {
      type: Object,
      required: false,
      default: () => {
        return {
          h: 0,
          w: 0,
          key: null
        }
      }
    },
    anim_uid: {
      type: String,
      required: false,
      default: ''
    },
    brandkit: {
      type: Object,
      required: false
    },
    playing: {
      type: Boolean,
      required: true
    },
    render_mode: {
      type: Boolean,
      required: false,
      default: false
    },
    active: {
      type: Boolean,
      required: true
    },
    complete: {
      type: Function,
      required: true
    },
    on_progress: {
      type: Function,
      required: true
    },
    indice: {
      type: Number,
      required: true
    },
    scene: {
      type: Object,
      required: true
    },
    active_playing: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      z_index: 0,
      progress: 0,
      active_z_index: 100,
    }
  },
  computed: {
    ...mapState({
      event_target: state => state.event_target,
      global_zoom: state => state.global_zoom,
      scene_context: state => state.scene_context,
    }),
    fonts() {
      if (this.$store && this.$store.state) {
        return this.$store.state.fonts
      } else {
        return []
      }
    },
    layers() {
      if (this.variant) {
        let layers = JSON.parse(JSON.stringify(this.variant.layers))
        layers = layers.sort((a, b) => a.position - b.position)
        return layers.map((l, i) => ({ ...l, position: (i + 1) * 10 }))
      }
    },
    variant() {
      return this.scene.canvas.variants.find(v => v.size.name === this.size.key)
    },
    a_size() {
      if (this.variant) {
        return {
          w: this.variant.size.display_dimensions[0],
          h: this.variant.size.display_dimensions[1]
        }
      } else {
        return {
          w: this.scene.canvas.width,
          h: this.scene.canvas.height
        }
      }
    }
  },
  watch: {
    variant: {
      immediate: true,
      handler(variant) {
        if (variant && this.active) {
          this.$nextTick(() => {
            if (this.$refs.editor) {
              this.$store.commit('SET_EDITOR_REF', this.$refs.editor)
            }
          })
        }
      }
    },
    active: {
      immediate: true,
      handler(active) {
        if (active) {
          this.z_index = this.active_z_index
          this.$nextTick(() => {
            if (this.$refs.editor) {
              this.$store.commit('SET_EDITOR_REF', this.$refs.editor)
              this.$refs.editor.focus(true)
            }
          })
        } else {
          this.$nextTick(() => {
            if (this.$refs.editor) {
              if (this.$refs.editor == this.$store.state.editor_ref)
                this.$store.commit('SET_EDITOR_REF', null)
              this.$refs.editor.focus(false)
            }
          })
          this.unset_layer()
          this.z_index = -Math.abs(this.indice)
        }
      }
    },
    playing() {
      if (!this.playing && this.active) {
        this.z_index = this.active_z_index
      }
    }
  },
  methods: {
    on_layer_select(layer) {
      if (layer) {
        this.$store.commit('SET_TAB', 'layers')
        this.$store.commit('SET_ACTIVE_LAYER', layer)
      }
    },
    set_size(variant) {
      this.$store.commit('SET_SIZE', variant.size.name)
    },
    on_focus(e) {
      if (this.scene_context.id !== this.scene.id && this.active)
        this.$store.commit('SET_SCENE_CONTEXT', { ...this.scene })
    },
    scene_data() {
      if (this.scene.canvas.feed.data.length === 0)
        return [{ brandkit: this.brandkit }]
      let brandkit = { ...this.brandkit }
      let default_data = this.scene.canvas.feed.data[0].data
      if (default_data.brandkit && default_data.brandkit.logos && default_data.brandkit.logos.length > 0 && default_data.brandkit.logos.every(l => !!l)) {
        brandkit.logos = default_data.brandkit.logos
      }
      return this.scene.canvas.feed.data.map(d => ({ ...d['data'], brandkit }))
    },
    unset_layer() {
      if (this.$refs.editor)
        this.$refs.editor.setActiveLayer(null)
    },
    on_save(type, method, object, variant) {
      if (type === 'layer' && method === 'update') {
        let layer = object
        this.$store.dispatch('UPDATE_BRIEFING_CANVAS_LAYER', { layer, briefing_canvas: this.scene, size: variant?.size })
      }

      if (type === 'layer' && method === 'create') {
        let layer = object
        this.$store.commit('ADD_BRIEFING_CANVAS_LAYER', { layer, briefing_canvas: this.scene, size: variant?.size })
      }

      if (type === 'layer' && method === 'delete') {
        let layer = object
        this.$store.commit('DELETE_BRIEFING_CANVAS_LAYER', { layer, briefing_canvas: this.scene, size: variant?.size })
      }

    },
    get_ref() {
      try {
        if (this.render_mode)
          return this.$refs.canvas
        return this.$refs.editor.$refs.canvas
      } catch (e) {
        return null
      }
    },
    duration() {
      if (this.get_ref()) {
        return this.get_ref().duration()
      }
      return 0
    },
    reset() {
      if (this.get_ref()) {
        this.get_ref()?.reset()
        if (this.active) {
          this.z_index = this.active_z_index
        } else {
          this.z_index = -Math.abs(this.indice)
        }
      }
    },
    pause() {
      if (this.get_ref()) {
        this.get_ref().pause()
      }
    },
    play() {
      this.z_index = this.active_z_index
      this.$nextTick(() => {
        this.get_ref()?.play()
      })
    },
    on_anim_progress(scene, e) {
      // this.progress = e.progress
      this.on_progress(scene, e)
    },
    on_anim_complete(scene, e) {
      this.complete(e)
      if (!this.render_mode)
        this.z_index = -Math.abs(this.indice)
    }
  }
}
</script>
