import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Background extends Component {
  constructor(opts = {}, config = {}) {
    super(opts)
    this.config_opts = config
  }

  name() {
    return 'Background'
  }

  labels() {
    return ['layer']
  }

  icon() {
    return 'fa-regular fa-photo-film'
  }

  config(opts = {}, config = {}) {
    config = {
      ...this.config_opts,
    }
    return Layer.factory({
      layer_type: 'background',
      text: '',
      position: 900,
      width: opts.canvas.width,
      height: opts.canvas.height,
      variable: true,
      variable_tag: 'lifestyle',
      config: {
        object_fit: 'cover',
        media_url: '',
        ...config
      },
      ...opts
    })
  }
}
