export const ACTIVATE_VARIANT = 'activate_variant'
export const TOGGLE_SETTINGS  = 'toggle_settings'
export const UPDATE_CANVAS    = 'update_canvas'
export const OPTIONS = 'set_options'
export const DIRTY = 'dirty'
export const SET_TOP_SEGMENT = 'set_top_segment'
export const PALETTE = 'palette'
export const SET_STENCILS = 'set_stencils'
export const SET_STATE = 'set_state'
export const UPDATE_CANVAS_FEED = 'update_canvas_feed'
