import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const STATE_PICK_VARIANT_SIZE = 'STATE_PICK_VARIANT_SIZE'
export const STATE_NEW_VARIANT = 'STATE_NEW_VARIANT'
export const STATE_DEFAULT = 'STATE_DEFAULT'

const state = () => ({
  ui_state: STATE_DEFAULT,
  brandkit: {
    usps: [],
    customer_quotes: [],
    colors: [],
    number_of_reviews: null,
    average_review_score: null
  },
  options: {
    event_target: new EventTarget(),
    studio: false,
    top_segment: null,
    autosave: false,
    show_settings: true,
    save_redirect_url: null,
    feature_toggles: {
      segments: false,
      feed_selector: false,
      sizes: false
    },
  },
  stencils: [],
  fonts: [],
  history: [],
  dirty: false,
  project_id: null,
  canvas: {
    background_image: '',
    background_color: '#FFFFFF',
    variants: [],
    used_by_catalog: false,
    palette: [],
    template: false
  },
  maps: {
    variant_id_map: {},
    layer_id_map: {}
  },
})

export default () => ({
  state,
  getters,
  actions,
  mutations
})
