import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class VideoPlayer extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })
  }

  name() {
    return 'Videoplayer'
  }

  static stand_alone() {
    return true
  }

  getVideoElement() {
    return $(`#video_layer_${this.uid}_${this.layer.id}`)[0];
  }

  seek(dur) {
    const video = this.getVideoElement();
    if (video) {
      const time = dur / 1000;
      if (!Number.isNaN(time) && Number.isFinite(time)) {
        video.pause();
        video.currentTime = time;
      } else {
        console.error('Invalid time value:', time);
      }
    }
  }

  animation() {
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
    })
    return timeline
  }

  play() {
    setTimeout(() => {
      const video = this.getVideoElement();
      if (video && video.paused) {
        video.play();
      }
    }, 1)
  }

  pause() {
    setTimeout(() => {
      const video = this.getVideoElement();
      if (video) video.pause();
    }, 1)
  }

  cleanup() {
    setTimeout(() => {
      const video = this.getVideoElement();
      if (video) {
        video.pause();
        video.currentTime = 0;
        video.load();
      }
    }, 1)
  }

  restart() {
    this.cleanup();
  }

  prepare() {
    setTimeout(() => {
      const video = this.getVideoElement();
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    })
  }
}
